import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, {useEffect} from "react";
import Home from "Home.js";

import {HashRouter as Router, Route, Switch, useLocation,} from "react-router-dom";
import TermsOfService from "./listings/TermsOfService";

import AboutUsPage from "./listings/Features.js";
import Listings from "./listings/Listings";
import Klobouky from "./listings/Klobouky";
import Utechov from "./listings/Utechov";
import Bystrc from "./listings/Bystrc";
import Hnevotin from "./listings/Hnevotin";
import Estimate from "listings/Estimate";
import Popuvky from "./listings/Popuvky";
import Kostelec from "./listings/Kostelec";
import PozemekKostelec from "./listings/PozemekKostelec";
import Belkovice from "./listings/Belkovice";
import Visnova from "./listings/Visnova";
import Praslavice from "./listings/Praslavice";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

export default function App() {
    return (
        <Router>
            <ScrollToTop/>
            <Switch>
                <Route exact path="/gdpr">
                    <TermsOfService/>
                </Route>
                <Route exact path="/sluzby">
                    <AboutUsPage/>
                </Route>
                <Route exact path="/">
                    <Home/>
                </Route>
                <Route exact path="/domov">
                    <Home/>
                </Route>
                <Route exact path="/nabidka">
                    <Listings/>
                </Route>
                <Route exact path="/odhad">
                    <Estimate/>
                </Route>
                <Route exact path="/pozemek-kostelec">
                    <PozemekKostelec/>
                </Route>
                <Route exact path="/belkovice">
                    <Belkovice/>
                </Route>
                <Route exact path="/visnova">
                    <Visnova/>
                </Route>
                <Route exact path="/praslavice">
                    <Praslavice/>
                </Route>
            </Switch>
        </Router>
    );
}

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};
