import React, {useState} from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import {SectionHeading, Subheading as SubheadingBase} from "../misc/Headings.js";
import {PrimaryButton} from "../misc/Buttons.js";
import {ReactComponent as QuotesLeftIcon} from "../../images/quotes-l.svg";
import {ReactComponent as QuotesRightIcon} from "../../images/quotes-r.svg";
import {ReactComponent as ChevronLeftIcon} from "feather-icons/dist/icons/chevron-left.svg";
import {ReactComponent as ChevronRightIcon} from "feather-icons/dist/icons/chevron-right.svg";
import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-4.svg";
import {ReactComponent as SvgDecoratorBlob2} from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;

const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-100 px-6 py-4 rounded-tl-3xl border`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-secondary-100`;

const QuotesLeft = tw(QuotesLeftIcon)`w-6 h-6 opacity-75 text-primary-500 inline-block mr-1 -mt-3`;
const QuotesRight = tw(QuotesRightIcon)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3`;

const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({
  subheading = "",
  heading = "Řekli o nás",
  testimonials = null,
  textOnLeft = false
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc:
          "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600954296/Kuchy%C5%88_Ob%C3%BDv%C3%A1k_02_-_titulna_ioyy9t.jpg",
      quote:
          "Využil jsem k prodeji bytu. Přístup na jedničku od homestagingu až po pomoc s daňovým přiznáním. Doporučuji!",
      customerName: "Pavel Žák",
      customerTitle: "Olomouc"
    },
    {
      imageSrc:
          "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1620936286/janskeho/1_reknfh.jpg",
      quote:
          "Velmi lidský přístup a skvělá komunikace. Určitě doporučuji.",
      customerName: "Filip Procházka ",
      customerTitle: "Praha"
    },
    {
      imageSrc:
          "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600887521/home_staging-living_room-featured_o98ql9.jpg",
      quote:
          "Spolupráce s týmem Neverejne s.r.o. pro nás byla při pořizování vlastního bydlení více než příjemná a užitečná. "
          + "Jejich profesionální, efektivní a poctivý přístup nám pomohl dosáhnout cíle mnohem rychleji, než bylo očekáváno. "
          + "Jako přidanou hodnotu rozhodně vnímáme pomoc při zařízení financování, že vše řešíte s těmi stejnými lidmi.",
      customerName: "Alžbeta Dobrotová",
      customerTitle: "Olomouc"
    }, {
      imageSrc:
          "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1601047253/testimonials2_c1z7mc.jpg",
      quote:
          "Empatie, lidský přístup a nasazení na 110 % - takto bych popsal spolupráci s týmem Neverejne s.r.o. Ani jsem nečekal, že budeme s rodinou tak brzy bydlet ve vlastním. Moc děkujeme!",
      customerName: "Antonín Barták",
      customerTitle: "Olomouc"
    }, {
      imageSrc:
          "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600890482/foto-hnevotin_i1gukp.jpg",
      quote:
          "Díky dokonale sehranému týmu Neverejne s.r.o. proběhl prodej nemovitosti s naprostou spokojeností na straně kupujícího i prodávajícího. Tato spolupráce určitě nebyla poslední",
      customerName: "Petr Fidler",
      customerTitle: "Zdárně rekonstruovaný dům v Hněvotíně "
    }
  ];

  if (!testimonials || testimonials.length === 0) {
    testimonials = defaultTestimonials;
  }
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
      <Container>
        <Content>
          <HeadingInfo tw="text-center lg:hidden" subheading={subheading} heading={heading}/>
          <TestimonialsContainer>
            <Testimonials>
              <Testimonial>
                <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>
                  {testimonials.map((testimonial, index) => (
                      <ImageAndControlContainer key={index}>
                        <Image imageSrc={testimonial.imageSrc}/>
                        <ControlContainer>
                          <ControlButton onClick={imageSliderRef?.slickPrev}>
                            <ChevronLeftIcon/>
                          </ControlButton>
                          <ControlButton onClick={imageSliderRef?.slickNext}>
                            <ChevronRightIcon/>
                          </ControlButton>
                        </ControlContainer>
                      </ImageAndControlContainer>
                  ))}
                </TestimonialImageSlider>
                <TextContainer textOnLeft={textOnLeft}>
                  <HeadingInfo tw="hidden lg:block" subheading={subheading} heading={heading}/>
                  <TestimonialTextSlider arrows={false} ref={setTextSliderRef} asNavFor={imageSliderRef} fade={true}>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialText key={index}>
                          <QuoteContainer>
                            <Quote>
                              <QuotesLeft/>
                              {testimonial.quote}
                              <QuotesRight/>
                            </Quote>
                          </QuoteContainer>
                          <CustomerInfo>
                            <CustomerTextInfo>
                              <CustomerName>{testimonial.customerName}</CustomerName>
                              <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                            </CustomerTextInfo>
                          </CustomerInfo>
                        </TestimonialText>
                    ))}
                  </TestimonialTextSlider>
                </TextContainer>
              </Testimonial>
            </Testimonials>
          </TestimonialsContainer>
        </Content>
        <DecoratorBlob1/>
        <DecoratorBlob2/>
      </Container>
  );
};

const HeadingInfo = ({subheading, heading, ...props}) => (
    <div {...props}>
      {subheading ? <Subheading>{subheading}</Subheading> : null}
      <HeadingTitle>{heading}</HeadingTitle>
    </div>
);
