import React, {useState} from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col mx-auto lg:w-1/2`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-8`;

const HousePlanSwitcher = tw.div`flex justify-center w-2/5 rounded-full mt-16 grid grid-cols-4 mx-auto gap-2`;
const LastRow = tw.div`flex justify-center w-2/5 rounded-full mx-auto gap-2 mt-4`;
const Dispozice = tw.div`hidden md:block`;
const DispoziceMobile = tw.div`block md:hidden`;

export default function FlatForm({ handleSubmit }) {
  const [formData, setFormData] = useState({
    dispozice: "1+kk", // 1+kk, 1+1, 2+kk, 2+1, 3+kk, 3+1, 4+kk, 4+1, jiné
    vlastnictvi: "",
    stav: "",
    konstrukce: "",
    sklep: false,
    garaz: false,
    balkon: false,
    vlastniZahrada: false,
    patro: 1,
    pocetPater: 1,
    address: "",
    podlahovaPlocha: "",
  });

  const Switch = ({ dispozice = "1+kk" }) => (
    <div style={{ textAlign: "center" }}>
      <input
        type="checkbox"
        id={dispozice}
        checked={formData.dispozice === dispozice}
        onClick={() => setFormData((prevData) => ({ ...prevData, dispozice }))}
      />
      <label style={{ paddingLeft: "10px" }} for={dispozice}>
        {dispozice}
      </label>
    </div>
  );

  const CustomSwitch = ({ label, property }) => (
    <label
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <Label style={{ marginRight: "10px" }}>{label}</Label>
      <ReactSwitch
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        checked={formData[property]}
        onChange={() =>
          setFormData((prevData) => ({
            ...prevData,
            [property]: !formData[property],
          }))
        }
      />
    </label>
  );

  const Text = tw.h2`px-4 inline-block text-2xl text-center font-bold w-full`;
  const Label = tw.div` text-lg text-center `;

  const optionsVlastnictvi = [
    { value: "osobni", label: "Osobní" },
    { value: "druzstevni", label: "Družstevní" },
    { value: "jine", label: "Jiné" },
  ];
  const optionsStav = [
    { value: "puvodni", label: "Původní" },
    { value: "porekonstrukci", label: "Po rekonstrukci" },
    { value: "novostavba", label: "Novostavba" },
  ];
  const optionsKonstrukce = [
    { value: "panel", label: "Panel" },
    { value: "cihla", label: "Cihla" },
    { value: "jina", label: "Jiná" },
  ];
  const optionsDispozice = [
    { value: "1+kk", label: "1+kk" },
    { value: "1+1", label: "1+1" },
    { value: "2+kk", label: "2+kk" },
    { value: "2+1", label: "2+1" },
    { value: "3+kk", label: "3+kk" },
    { value: "3+1", label: "3+1" },
    { value: "4+kk", label: "4+kk" },
    { value: "4+1", label: "4+1" },
    { value: "jine", label: "jiné" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <div>
      <Text>Dispozice</Text>
      <Dispozice>
        <HousePlanSwitcher>
          <Switch dispozice="1+kk" />
          <Switch dispozice="1+1" />
          <Switch dispozice="2+kk" />
          <Switch dispozice="2+1" />
          <Switch dispozice="3+kk" />
          <Switch dispozice="3+1" />
          <Switch dispozice="4+kk" />
          <Switch dispozice="4+1" />
        </HousePlanSwitcher>
        <LastRow>
          <Switch dispozice="jiné" />
        </LastRow>
      </Dispozice>
      <DispoziceMobile>
        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsDispozice}
            placeholder="Dispozice"
            defaultInputValue={"1+kk"}
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, dispozice: value }))
            }
          />
        </div>
      </DispoziceMobile>
      <Form onSubmit={onSubmit} id="emailForm">
        <Input
          type="address"
          name="Adresa"
          placeholder="Adresa"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({ ...prevData, address: value }));
          }}
        />
        <Input
          type="number"
          name="podlahovaPlocha"
          placeholder="Podlahová plocha (m2)"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({
              ...prevData,
              podlahovaPlocha: value,
            }));
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          Byt se nachází v {"  "}
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <Select
              options={(() =>
                new Array(30)
                  .fill()
                  .map((_, idx) => ({ value: idx, label: idx })))()}
              placeholder="1"
              onChange={({ value }) =>
                setFormData((prevData) => ({ ...prevData, patro: value }))
              }
            />
          </div>
          patře z celkových{" "}
          <div style={{ marginLeft: "10px", marginRight: "10px" }}>
            <Select
              options={(() =>
                new Array(30)
                  .fill()
                  .map((_, idx) => ({ value: idx, label: idx })))()}
              placeholder="1"
              onChange={({ value }) =>
                setFormData((prevData) => ({ ...prevData, pocetPater: value }))
              }
            />{" "}
          </div>
          pater
        </div>

        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsVlastnictvi}
            placeholder="Vlastnictví"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, vlastnictvi: value }))
            }
          />
        </div>

        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsStav}
            placeholder="Stav nemovitosti"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, stav: value }))
            }
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsKonstrukce}
            placeholder="Konstrukce"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, konstrukce: value }))
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSwitch label="Sklep" property="sklep" />
          {formData.sklep && (
            <Input
              type="number"
              name="sklepPlocha"
              placeholder="Kolik m2"
              style={{ marginLeft: "10px" }}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  sklepPlocha: value,
                }));
              }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSwitch
            label="Balkon/Terasa/Lodžie"
            property="balkonTerasaLodzie"
          />
          {formData.balkonTerasaLodzie && (
            <Input
              type="number"
              name="balkon/terasa/lodzie"
              placeholder="Plocha (m2)"
              style={{ marginLeft: "10px" }}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  balkonTerasaLodziePlocha: value,
                }));
              }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSwitch label="Vlastní zahrada" property="vlastniZahrada" />
          {formData.vlastniZahrada && (
            <Input
              type="number"
              name="zahradaPlocha"
              placeholder="Plocha (m2)"
              style={{ marginLeft: "10px" }}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  zahradaPlocha: value,
                }));
              }}
            />
          )}
        </div>

        <SubmitButton type="submit">Odeslat</SubmitButton>
      </Form>
    </div>
  );
}
