import tw from "twin.macro";
import {ReactComponent as VirtualTourIconBase} from "../../images/send.svg";
import {SectionHeading} from "./Headings";
import {Container as ContainerBase} from "./Layouts";
import React from "react";

const PrimaryButton = tw.button`font-bold text-xl px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 focus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`;
const PhoneColumnNewsletter = tw.div`flex items-center flex-col sm:flex-row`;
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`;
const VirtualTourIcon = tw(VirtualTourIconBase)`w-16 h-16 text-primary-500`;
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`;
const HeadingNewsletter = tw(
    SectionHeading
)`text-gray-100 sm:text-left leading-none`;
const Button = tw(
    PrimaryButton
)`w-full sm:w-auto mt-6 sm:mt-0 sm:rounded-l-none py-4 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-300 border border-primary-500 hocus:border-primary-700`;

const ContainerNewsletter = tw(ContainerBase)`bg-secondary-800 -mx-8`;

function VirtualTour(link) {
    return (
        <ContainerNewsletter>
            <Content>
                <Row>
                    <PhoneColumnNewsletter>
                        <VirtualTourIcon/>
                        <HeadingInfoContainer>
                            <HeadingNewsletter>Virtuální prohlídka</HeadingNewsletter>
                        </HeadingInfoContainer>
                    </PhoneColumnNewsletter>
                    <FormColumn>
                        <Button
                            onClick={() =>
                                (window.location.href = link.link)
                            }
                        >
                            Vstoupit
                        </Button>
                    </FormColumn>
                </Row>
            </Content>
        </ContainerNewsletter>
    );
}

export default VirtualTour;