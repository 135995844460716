import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import {SectionHeading} from "components/misc/Headings";
import {ReactComponent as BuildingIcon} from "images/building-icon.svg";
import {ReactComponent as AreaIcon} from "feather-icons/dist/icons/layers.svg";
import {ReactComponent as LocationIcon} from "feather-icons/dist/icons/map-pin.svg";
import {ReactComponent as ChevronLeftIcon} from "feather-icons/dist/icons/chevron-left.svg";
import {ReactComponent as ChevronRightIcon} from "feather-icons/dist/icons/chevron-right.svg";
import {Link, useHistory} from "react-router-dom";
import {freeProperties, reservedProperties, soldProperties} from "../../data/listings";

const PrimaryButton = tw.button`font-bold text-xl mt-10 px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 focus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButtonBase = tw.button`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-600 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButtonFreeBase = tw.button`px-8 py-3 font-bold rounded bg-green-500 text-gray-100 hocus:bg-green-600 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButtonReservedBase = tw.button`px-8 py-3 font-bold rounded bg-yellow-500 text-gray-100 hocus:bg-yellow-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const PrimaryButtonSoldBase = tw.button`px-8 py-3 font-bold rounded bg-red-500 text-gray-100 hocus:bg-red-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;

const PrimaryButtonFree = tw(
    PrimaryButtonFreeBase
)`bg-green-500 mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const PrimaryButtonSold = tw(
    PrimaryButtonSoldBase
)`bg-red-700 mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
const PrimaryButtonReserved = tw(
    PrimaryButtonReservedBase
)`bg-yellow-500 mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 `;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const Pricing = tw.p`text-2xl font-bold text-center mt-2 text-primary-900 sm:mt-4`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-900`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center m-2`}
  }
`;
const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
    `background-image: url("${props.imageSrc}");`,
    tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center text-center`;
const Title = tw.h5`text-2xl font-bold`;
const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mb-2 sm:mb-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

export default () => {
    // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
    const [sliderRef, setSliderRef] = useState(null);
    let history = useHistory();
    const sliderSettings = {
        arrows: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                },
            },

            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const cards = freeProperties.concat(reservedProperties).concat(soldProperties);

    const renderButton = (property) => {
        const {state} = property;
        if (state === "free") {
            return (
                <PrimaryButtonFree onClick={() => history.push(property.primaryButtonUrl)}> Volný
                </PrimaryButtonFree>
            );
        } else if (state === "reserved") {
            return (
                <PrimaryButtonReserved
                    onClick={() => history.push(property.primaryButtonUrl)}
                >
                    Rezervováno
                </PrimaryButtonReserved>
            );
        } else if (state === "sold") {
            return <PrimaryButtonSold>
                Prodáno
            </PrimaryButtonSold>;
        }
    };

    return (
        <Container>
            <Content>
                <HeadingWithControl>
                    <Heading>Nabídka nemovitostí</Heading>
                    <Controls>
                        <PrevButton onClick={sliderRef?.slickPrev}>
                            <ChevronLeftIcon/>
                        </PrevButton>
                        <NextButton onClick={sliderRef?.slickNext}>
                            <ChevronRightIcon/>
                        </NextButton>
                    </Controls>
                </HeadingWithControl>
                <CardSlider ref={setSliderRef} {...sliderSettings}>
                    {cards.map((card, index) => (
                        <Card key={index}>
                            <Link to={card.state === 'free' ? card.primaryButtonUrl : "#"} className="link-card">
                                <CardImage imageSrc={card.imageSrc}/>
                                <TextInfo>
                                    <SecondaryInfoContainer>
                                        <IconWithText>
                                            <IconContainer>
                                                <BuildingIcon/>
                                            </IconContainer>
                                            <Text>{card.dispositionText}</Text>
                                        </IconWithText>
                                        <IconWithText>
                                            <IconContainer>
                                                <AreaIcon/>
                                            </IconContainer>
                                            <Text>
                                                {card.areaText}m<sup>2</sup>
                                            </Text>
                                        </IconWithText>
                                        <IconWithText>
                                            <IconContainer>
                                                <LocationIcon/>
                                            </IconContainer>
                                            <Text>{card.locationText}</Text>
                                        </IconWithText>
                                    </SecondaryInfoContainer>
                                    <TitleReviewContainer>
                                        <Title>{card.title}</Title>
                                    </TitleReviewContainer>
                                    <Pricing>{card.pricingText}</Pricing>
                                    <Description>
                                        Splátka hypotéky:{" "}
                                        <PricingMonthly>{card.monthly}</PricingMonthly> / měsíčně
                                    </Description>
                                </TextInfo>
                                {renderButton(card)}
                            </Link>
                        </Card>
                    ))}
                </CardSlider>
                <div id="button_wrapper" style={{paddingTop: "2rem"}}>
                    <Link to="/nabidka">
                        <PrimaryButton onClick={sliderRef?.slickPrev}
                                       style={{display: "block", margin: "auto"}}>
                            Zobrazit další nabídky ...
                        </PrimaryButton>
                    </Link>
                </div>
            </Content>
        </Container>
    );
};
