import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                    card = {
                        id: 1,
                        title: "Kompletně zařízený byt kousek od Brna",
                        tourLink: "https://my.matterport.com/show/?m=5L3RpJPhhB5",
                        subtitle:
                            "Nabízíme k prodeji kompletně zařízený a prosluněný byt 2+kk o výměře 62 m2 v obci Popůvky, nedaleko města Brna.",
                        monthly: "28.600 Kč",
                        price: "5.990.000 Kč",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/1_xee4l2.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/1_xee4l2.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/2_egsgy5.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/2_egsgy5.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/3_vrfghb.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/3_vrfghb.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/4_dyoqb8.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/4_dyoqb8.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/5_jem0i9.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/5_jem0i9.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/6_w5mf71.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/6_w5mf71.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/7_oiogxe.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/7_oiogxe.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/8_j3sayn.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/8_j3sayn.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/9_cxo5rc.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/9_cxo5rc.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/10_fstfp9.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/10_fstfp9.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/11_jcbfee.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/11_jcbfee.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/12_jrmceo.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/12_jrmceo.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/13_hv0zin.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/13_hv0zin.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/14_wv4d4p.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/14_wv4d4p.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/15_sripf3.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/15_sripf3.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1660153407/popuvky/16_weqa2f.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1660153407/popuvky/16_weqa2f.jpg",
                            },
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>{card.subtitle}</SubHeading>
                            <Price>{card.price}</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                                / měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <Link href="tel:601 101 463">601 101 463</Link>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Tento příjemný byt <b>zaručeně splňuje i náročné představy o bydlení v klidné a vyhledávané lokalitě s dobrou
                        dostupností </b> do města a poskytuje ideální útočiště pro páry či mladé rodiny.
                    </Description>
                    <Description>
                        Byt se nachází v prvním patře bytového domu, který byl <b>zkolaudován v roce 2009 a je kompletně zateplen.</b>
                    </Description>
                </Container>
                <VirtualTour link={card.tourLink}/>
                <Container>
                    <Description>
                        Světlá vstupní chodba vás postupně přivede do jednotlivých místností bytu a je vybavena vestavěnou skříní.
                        Centrálním místem pro trávení společného volného času, ať už při vaření či odpočinku na pohodlné sedačce,
                        pro vás však zajisté bude kuchyně spojená s obývacím pokojem. A díky balkonovým dveřím si zde navíc můžete
                        užívat příjemného celodenního slunce. Kuchyňská linka je laděna do příjemných neutrálních barev, disponuje
                        vestavěnými spotřebiči a nabízí dostatek úložného prostoru. V koupelně, která je také slazena do světlých
                        tónů, najdete vanu, skříňku s umyvadlem, zrcadlem a pračku. Dále se nachází ložnice orientovaná do
                        zahrady, disponující komfortní šatní skříní a rovněž přístupem na balkon. Součástí bytu jsou 2 balkony,
                        obě místnosti mají tedy vlastní vstup. K bytu náleží sklep, parkovací stání před domem, a navíc také
                        zahrada, kterou lze využít například pro pěstování sezónní zeleniny či k venkovnímu posezení s přáteli.
                    </Description>
                    <Description>
                        Obec Popůvky nabízí perfektní <b>občanskou vybavenost a dostupnost do města. </b> Brno je vzdáleno přibližně 5 min
                        autem, avšak díky frekventované hromadné dopravě zde <b>ani není auto každodenní nutností. </b> V obci se nachází
                        mateřská a základní škola, restaurace, kavárny, obchody, sportoviště i dětská hřiště. <b>Krásné okolí vybízí
                        k procházkám do přírody – třeba k Dolnímu rybníku nebo Bosonožskému hájku.</b>
                    </Description>
                </Container>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
