import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";

import Footer from "components/footers/ContactFooter.js";
import ContactUsForm from "../components/forms/ContactForm";
import Testimonials from "../components/testimonials/Testimonials";
import OfferGrid from "../components/cards/OfferGrid";

export default () => {
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

    return (
        <AnimationRevealPage>
            <Hero/>
            <OfferGrid
                heading={
                    <>
                        Aktuální <HighlightedText>nabídky.</HighlightedText>
                    </>
                }
            />
            <Testimonials/>
            <ContactUsForm/>
            <Footer/>
        </AnimationRevealPage>
    );
};
