import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
  SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;
const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;

const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const Text = styled.div`
  ${tw`text-lg  text-gray-800 text-justify`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({
  card = {
    id: 1,
    title: "Chata s pohádkovou atmosférou a velkorysým pozemkem",
    images: [
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/1_whbxwx.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/1_whbxwx.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/2_hlwszb.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/2_hlwszb.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/3_taxqsp.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/3_taxqsp.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/4_rkcm1s.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/4_rkcm1s.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/5_vzuxse.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/5_vzuxse.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/6_ldc2ps.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/6_ldc2ps.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/7_kod0q1.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/7_kod0q1 .jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/8_kkodik.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/8_kkodik.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/9_hrhrhy.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/9_hrhrhy.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/10_czotl4.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/10_czotl4.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/11_gqcpzo.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/11_gqcpzo.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1618234864/chata/12_breafj.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1618234864/chata/12_breafj.jpg",
      },
    ],
  },
}) => {
  return (
    <>
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 30,
          position: "absolute",
          zIndex: 10,
          width: "100%",
          height: "10rem",
          background:
            "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
          color: "white",
        }}
      >
        <Header links={navLinks} />
      </div>

      <ImageGallery
        showBullets
        showPlayButton={false}
        showThumbnails={false}
        items={card.images}
      />
      <AnimationRevealPage>
        <Container>
          <TwoColumn>
            <TitleColumn>
              <Heading>{card.title}</Heading>
              <SubHeading>Prodej chaty 120 m² s pozemekem 2 276 m²</SubHeading>
              <Price>3 900 000 Kč</Price>
              <MonthlyDescription>
                splátka hypotéky <PricingMonthly>12.450 Kč</PricingMonthly> /
                měsíčně
              </MonthlyDescription>
            </TitleColumn>
            <PhoneColumn textOnLeft={false}>
              <IconWithText>
                <IconContainer>
                  <PhoneIcon />
                </IconContainer>
                <ContactDescription>
                  <Link href="tel:601 101 463">601 101 463</Link>
                </ContactDescription>
              </IconWithText>
            </PhoneColumn>
          </TwoColumn>
          <Text>
            <p>
              Nabízíme k prodeji{" "}
              <b>
                malebnou dvoupodlažní chatu s velkorysým pozemkem a pohádkovou
                atmosférou
              </b>
              v rekreační oblasti Valašských Klobouk. Celková rozloha pozemku je
              2 276 m2, z čehož 102 m2 přísluší zastavěné ploše a 2 174 m2
              zahradě.
            </p>
            <p>
              Při vstupu do předsíně si povšimnete, že chata{" "}
              <b>působí útulně a poskytuje dostatek soukromí.</b> Přízemí tvoří
              kuchyně a obývací pokoj s <b>dominantními krbovými kamny. </b> Po
              schodech nahoru se dostanete do 1. patra, kde se rozléhají tři
              pokoje a koupelna. Krásy okolí můžete nerušeně pozorovat z{" "}
              <b>
                prostorné terasy, ať už ráno při východu slunce nebo večer při
                svitu hvězd.{" "}
              </b>
              Pro uložení sezónních věci či staršího vybavení můžete využít{" "}
              <b> půdní prostor. Chata je částečně podsklepena.</b> Součástí je
              také prostorné <b>venkovní posezení,</b> kde můžete s radostí
              pohostit svou rodinu či přátele.
            </p>
            <p>
              Mezi hlavní výhody této chatky patří{" "}
              <b>dostatek soukromí, útulnost a kouzlo okolní přírody,</b>které
              Vás zajisté dostane. Takže pokud hledáte vhodné prostředí pro{" "}
              <b>
                odpočinek od všedních dnů, načerpání nové energie a inspirace či
                podporu kreativity,{" "}
              </b>
              je pro vás tato nabídka tou správnou volbou.
            </p>
            <p>
              V přilehlých Valašských Kloboukách se nachází veškerá občanská
              vybavenost,{" "}
              <b>letní koupaliště a bohatá městská památková zóna. </b>{" "}
              Milovníci přírody si zde také přijdou na své. Nachází se zde totiž{" "}
              <b>přírodní rezervace Ploščiny,</b> na kterou navazuje{" "}
              <b>cyklostezka Bevlava</b> vedoucí až do sousedního města Brumov –
              Bylnice,{" "}
              <b>
                lyžařský areál, rozhledna Královec, pozorovatelna Durch a
                spousta dalších možností turistických výletů
              </b>{" "}
              po okolí, což tvoří přidanou hodnotu této nabídky.
            </p>
            <p>
              Zkrátka, pokud byste rádi viděli tohle místo s jedinečnou
              atmosférou, neváhejte nás kontaktovat, rádi vás provedeme.
            </p>
          </Text>
        </Container>
        <ContactForm subject={card.title} />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
