import React, {useState} from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col mx-auto lg:w-1/2`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-8`;

export default function HouseForm({ handleSubmit }) {
  const [formData, setFormData] = useState({
    address: "",
    stav: "",
    plochaPozemku: "",
    konstrukce: "",
    podlahovaPlocha: "",
    sklep: false,
    garaz: false,
  });

  const CustomSwitch = ({ label, property }) => (
    <label
      style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
    >
      <Label style={{ marginRight: "10px" }}>{label}</Label>
      <ReactSwitch
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={30}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={20}
        width={48}
        checked={formData[property]}
        onChange={() =>
          setFormData((prevData) => ({
            ...prevData,
            [property]: !formData[property],
          }))
        }
      />
    </label>
  );

  const Label = tw.div` text-lg text-center `;

  const optionsStav = [
    { value: "puvodni", label: "Původní" },
    { value: "porekonstrukci", label: "Po rekonstrukci" },
    { value: "novostavba", label: "Novostavba" },
  ];

  const optionsKonstrukceDum = [
    { value: "cihla", label: "Cihla" },
    { value: "Dřevostavba", label: "Dřevostavba" },
    { value: "jina", label: "Jiná" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} id="emailForm">
        <Input
          type="address"
          name="Adresa"
          placeholder="Adresa"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({ ...prevData, address: value }));
          }}
        />
        <Input
          type="number"
          name="Plocha pozemku"
          placeholder="Plocha pozemku (m2)"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({
              ...prevData,
              plochaPozemku: value,
            }));
          }}
        />
        <Input
          type="number"
          name="podlahovaPlocha"
          placeholder="Podlahová plocha (m2)"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({
              ...prevData,
              podlahovaPlocha: value,
            }));
          }}
        />

        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsStav}
            placeholder="Stav nemovitosti"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, stav: value }))
            }
          />
        </div>
        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsKonstrukceDum}
            placeholder="Konstrukce"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, konstrukce: value }))
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSwitch label="Sklep" property="sklep" />
          {formData.sklep && (
            <Input
              type="number"
              name="Kolik m2"
              placeholder="Plocha (m2)"
              style={{ marginLeft: "10px" }}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  sklepPlocha: value,
                }));
              }}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomSwitch label="Garáž" property="garaz" />
          {formData.garaz && (
            <Input
              type="number"
              name="Kolik m2"
              placeholder="Plocha (m2)"
              style={{ marginLeft: "10px" }}
              onChange={(e) => {
                const value = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  garazPlocha: value,
                }));
              }}
            />
          )}
        </div>
        <SubmitButton type="submit">Odeslat</SubmitButton>
      </Form>
    </div>
  );
}
