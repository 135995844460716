import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings.js";

import defaultCardImage from "images/shield-icon.svg";

import HomestagingIcon from "images/homestaging-icon.svg";
import PresentationIcon from "images/presentation-icon.svg";
import MarketingIcon from "images/marketing-icon.svg";
import LawIcon from "images/scale-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import FinancingIcon from "images/financing-icon.svg"
import {Link} from "react-router-dom";

const PrimaryButton = tw.button`font-bold text-2xl mt-10 px-8 lg:px-10 py-3 rounded bg-primary-500 text-gray-100 focus:bg-primary-700 focus:shadow-outline focus:outline-none transition duration-300`;
const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-center text-center sm:text-center h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-12 h-12 text-center`}
    }
  }
  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider uppercase text-center font-bold text-xl leading-none`}
  }
`;

export default ({
  cards = null,
  heading = "6 služeb, kterými zajistíme bezstarostný a bleskový prodej Vaší nemovitosti",
  subheading = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: HomestagingIcon,
      title: "Homestaging",
      description: "upravíme nemovitost tak, že se do ní každý zamiluje"
    },
    {
      imageSrc: FastIconImage,
      title: "Profesionální prezentace",
      description: "připravíme prezentaci hodnou titulních stránek magazínu"
    },
    {
      imageSrc: MarketingIcon,
      title: "Marketing",
      description: "zpropagujeme nemovitost tak, aby se prodala ani se nenadějete"
    },
    {
      imageSrc: PresentationIcon,
      title: "Prohlídka",
      description: "děláme prohlídky, které jsou pro kupující zážitkem"
    },
    {
      imageSrc: LawIcon,
      title: "Právní poradenství a servis",
      description: "vyřešíme vše za vás a vy nezaplatíte ani korunu navíc"
    },
    {
      imageSrc: FinancingIcon,
      title: "Financování",
      description: "ušetríme vám čas, starosti i peníze"
    }
  ];

  if (!cards) {
    cards = defaultCards;
  }

  return (
      <Container>
        <ThreeColumnContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <VerticalSpacer/>
          {cards.map((card, i) => (
              <Column key={i}>
                <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt=""/>
              </span>
                  <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                    <p>{card.description}</p>
              </span>
                </Card>
              </Column>
          ))}
          <Link to="/sluzby">
            <PrimaryButton>
              Více
            </PrimaryButton>
          </Link>
        </ThreeColumnContainer>
      </Container>
  );
};
