import React from "react";
import {NavLink, NavLinks} from "../../components/headers/light";
import {Link} from "react-router-dom";

export const navLinks = () => [
  <NavLinks>
    <NavLink to="/nabidka">Nabídka</NavLink>

    <NavLink href="/sluzby">Služby</NavLink>
    <NavLink
      onClick={() => document.getElementById("contact_form").scrollIntoView()}
    >
      <Link>Kontakt</Link>
    </NavLink>
    <NavLink>
      <Link>Daco</Link>
    </NavLink>
  </NavLinks>,
];
