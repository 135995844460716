import React, {useEffect, useRef, useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";
import Select from "react-select";
import axios from "axios";
import Footer from "components/footers/ContactFooter.js";
import {SectionHeading} from "components/misc/Headings";
import styled from "styled-components";
import {PrimaryButton} from "components/misc/Buttons";
import CottageIcon from "components/CottageIcon";
import LandIcon from "components/LandIcon";
import HouseIcon from "components/HouseIcon";
import FlatIcon from "components/FlatIcon";
import ReCAPTCHA from "react-google-recaptcha";
import LandForm from "../components/LandForm";
import HouseForm from "../components/HouseForm";
import FlatForm from "../components/FlatForm";

const CAPTCHA_KEY = "6LfsptAZAAAAAKev6HIwh287q4ybEbY5JtBSQZFR";

const Header = tw(SectionHeading)``;
const Content = tw.div`pt-12 pb-12 w-full m-auto`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-8`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xl sm:inline-block sm:w-auto rounded-full my-16`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-4 rounded-full focus:outline-none text-sm font-bold text-gray-700 transition duration-300 mx-2`}
  ${(props) => props.active && tw`bg-primary-500 text-gray-100 `}
  ${(props) =>
    !props.active && tw`hover:border-primary-500 border border-white`}
`;
const Center = tw.div`w-full flex justify-center items-center`;
const Container = tw.div`w-full block mx-16 m-auto`;
const Step2Container = tw.div`w-1/2 grid mx-16 m-auto my-16`;
const PropertyType = tw.div`hidden md:block`;
const PropertyTypeMobile = tw.div`block md:hidden`;
export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Text = tw.h2`px-4 inline-block text-2xl text-center font-bold w-full`;

  useEffect(() => {
    document.getElementById("estimate").scrollIntoView({ behavior: "smooth" });
  }, []);

  const optionsType = [
    { value: "byt", label: "Byt" },
    { value: "dum", label: "Dům" },
    { value: "pozemek", label: "Pozemek" },
    { value: "chata", label: "Chata" },
  ];
  const [formData, setFormData] = useState({});
  const [contactInfo, setContactInfo] = useState({
    email: "",
    phoneNumber: "",
  });
  const [step, setStep] = useState(0); // 0 | 1 | 2
  const [selectedPropertyType, setSelectedPropertyType] = useState("byt"); // byt | dum | pozemek | chata

  const onSubmit = (formData) => {
    if (areAllFieldsFilled(formData)) {
      setFormData(formData);
      setStep(1);
    } else {
      alert("Je potřeba vyplnit všechna pole!");
    }
  };

  const onSendEmail = () => {
    if (areAllFieldsFilled(contactInfo)) {
      if (!passedCaptcha) {
        alert("Nejdřív je potřebné potvrdit, že “nejsi robot”");
      } else {
        const BASE_URL =
          process.env.NODE_ENV === "development"
            ? "http://localhost:8080"
            : "https://www.neverejne.cz";

        const data = { ...formData, typ: selectedPropertyType };
        axios
          .post(`${BASE_URL}/api/user/contact-komercky`, {
            email: contactInfo.email,
            phoneNumber: contactInfo.phoneNumber,
            message: JSON.stringify(data, null, 2),
            subject: "odhad"
          })
          .then(() => {
            document.getElementById("emailForm").reset();
            captchaRef.current.reset();
            setStep(2)
          })
          .catch((err) => {
            console.log(err);
            document.getElementById("emailForm").reset();
            captchaRef.current.reset();
            setStep(2)
          });
      }
    } else {
      alert("Je potřeba vyplnit všechna pole!");
    }
  };

  const [passedCaptcha, setPassedCaptcha] = useState(false);
  const captchaRef = useRef();

  const onCaptchaClick = () => {
    setPassedCaptcha(true);
  };

  const renderBody = () => {
    if (step === 0) {
      return (
        <>
          <Center>
            <PropertyType>
              <PlanDurationSwitcher>
                <SwitchButton
                  active={selectedPropertyType === "byt"}
                  onClick={() => setSelectedPropertyType("byt")}
                >
                  <FlatIcon
                    color={selectedPropertyType === "byt" ? "white" : "black"}
                  />
                  Byt
                </SwitchButton>
                <SwitchButton
                  active={selectedPropertyType === "dum"}
                  onClick={() => setSelectedPropertyType("dum")}
                >
                  <HouseIcon
                    color={selectedPropertyType === "dum" ? "white" : "black"}
                  />
                  Dům
                </SwitchButton>
                <SwitchButton
                  active={selectedPropertyType === "pozemek"}
                  onClick={() => setSelectedPropertyType("pozemek")}
                >
                  <LandIcon
                    color={
                      selectedPropertyType === "pozemek" ? "white" : "black"
                    }
                  />
                  Pozemek
                </SwitchButton>
                <SwitchButton
                  active={selectedPropertyType === "chata"}
                  onClick={() => setSelectedPropertyType("chata")}
                >
                  <CottageIcon
                    color={selectedPropertyType === "chata" ? "white" : "black"}
                  />
                  Chata
                </SwitchButton>
              </PlanDurationSwitcher>
            </PropertyType>
            <PropertyTypeMobile>
              <div style={{ marginTop: "15px" }}>
                <Select
                  options={optionsType}
                  placeholder="Typ nemovitosti"
                  defaultInputValue={"byt"}
                  onChange={({ value }) => setSelectedPropertyType(value)}
                />
              </div>
            </PropertyTypeMobile>
          </Center>
          <Container>
            {selectedPropertyType === "byt" && (
              <FlatForm handleSubmit={onSubmit} />
            )}
            {selectedPropertyType === "dum" && (
              <HouseForm handleSubmit={onSubmit} />
            )}
            {selectedPropertyType === "pozemek" && (
              <LandForm handleSubmit={onSubmit} />
            )}
            {selectedPropertyType === "chata" && (
              <HouseForm handleSubmit={onSubmit} />
            )}
          </Container>
        </>
      );
    } else if (step === 1) {
      return (
        <Step2Container>
          <Text>Váš odhad je zpracován.</Text>
          <Text>Zadejte prosím své kontaktní údaje, kam Vám jej zašleme.</Text>
          <Input
            type="email"
            name="email"
            placeholder="Email"
            onChange={(e) => {
              const value = e.target.value;
              setContactInfo((prevData) => ({ ...prevData, email: value }));
            }}
          />

          <Input
            type="tel"
            name="phoneNumber"
            placeholder="Telefón"
            onChange={(e) => {
              const value = e.target.value;
              setContactInfo((prevData) => ({
                ...prevData,
                phoneNumber: value,
              }));
            }}
          />
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={CAPTCHA_KEY}
            onChange={onCaptchaClick}
            style={{ margin: "auto", marginTop: 30 }}
          />
          <SubmitButton onClick={onSendEmail}>Odeslat</SubmitButton>
        </Step2Container>
      );
    } else {
      return (
        <Step2Container>
          <Text>Vaše kontaktní údaje byly zpracovány.</Text>
          <Text>Náš tým Vás bude kontaktovat.</Text>
        </Step2Container>
      );
    }
  };

  return (
    <AnimationRevealPage>
      <Hero />
      <Content id="estimate">
        <Header>
          Odhad <HighlightedText>ZDARMA</HighlightedText>
        </Header>
        {renderBody()}
      </Content>
      <Footer />
    </AnimationRevealPage>
  );
};

const areAllFieldsFilled = (formData) => {
  const keys = Object.keys(formData);
  for (let i = 0; i < keys.length; i++) {
    if (formData[keys[i]] === "") {
      return false;
    }
  }
  return true;
};
