import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                    card = {
                        title: "Prostorný dům v Bělkovicích-Lašťanech s velkým potenciálem",
                        tourLink: "https://my.matterport.com/show/?m=URys7EqU5jd",
                        subtitle:
                            "Nabízíme k prodeji Prostorný dům v Bělkovicích-Lašťanech s velkým potenciálem o výměře 244 m2 s pozemkem o výměře 559 m2" +
                            " v Bělkovicích-Lašťanech.",
                        monthly: "46.500 Kč",
                        price: "6.990.000 Kč",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/1_xkgrqw.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/1_xkgrqw.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/2_gmywga.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/2_gmywga.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/3_m1jhcc.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/3_m1jhcc.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/4_avwsik.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/4_avwsik.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/5_kd9m4q.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/5_kd9m4q.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/6_sqrss0.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/6_sqrss0.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/7_iisyyv.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/7_iisyyv.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/8_gvxynw.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/8_gvxynw.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/9_tp78eg.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/9_tp78eg.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/10_ka4rmz.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/10_ka4rmz.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/11_thmhcv.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/11_thmhcv.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/12_ac3y4s.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/12_ac3y4s.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/13_d0cugo.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/13_d0cugo.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/14_bzvoah.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/14_bzvoah.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/15_wxcrob.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/15_wxcrob.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/16_jmql2m.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/16_jmql2m.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/17_yqedjp.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/17_yqedjp.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/18_sy3xxl.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/18_sy3xxl.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/19_m8abii.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/19_m8abii.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/20_qsaaxp.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/20_qsaaxp.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/21_klio18.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/21_klio18.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695228617/belkovice/22_dmpxg4.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695228617/belkovice/22_dmpxg4.jpg",
                            },
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>{card.subtitle}</SubHeading>
                            <Price>{card.price}</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                                / měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <Link href="tel:601 101 463">601 101 463</Link>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Toužíte po útulném a rodinném domově, který nabízí komfort a pohodu? Máme pro vás skvělou
                        nabídku! Představujeme Vám dům s užitnou plochou 244 metrů čtverečních a pozemkem 559 metrů
                        čtverečních, který se nachází v malebných Bělkovicích-Laštanech. Toto místo nabízí idylickou
                        kombinaci klidného venkovského prostředí v blízkosti všech potřebných služeb.
                    </Description>
                    <Description>
                        S celkovým pozemkem 559 metrů čtverečních máte dostatek prostoru pro zahradní radovánky,
                        zahradní grilování či prostě jen pro odpočinek na čerstvém vzduchu.
                    </Description>
                    <Description>
                        Interiér domu vás nadchne svým vkusným zařízením a přívětivou atmosférou. Jeho klíčovou
                        vlastností je rozdělení na dvě samostatné jednotky na horním patře (4+kk) a dolním patře (4+1),
                        které mají samostatný vstup. Každá z nich navíc disponuje nezávislými měřiči všech energií.
                        Aktuálně je zde taky i cukrářská výrobna, která má taky samostatní vstup.
                    </Description>
                </Container>
                <VirtualTour link={card.tourLink}/>
                <Container>
                    <Description>
                        Celý dům je tak vhodný bud pro vetší rodinu nebo ho můžete pronajímat jako dva samostatné byty,
                        případně kombinaci obou možností.
                    </Description>
                    <Description>
                        Rekonstrukce v letech 2013 a 2017 zaručují moderní vybavení a spolehlivost, což je v dnešní
                        uspěchané době klíčové. Tato nemovitost je opravdu unikátní a může splnit mnoho vašich přání –
                        ať už hledáte rodinný dům pro vaši vlastní potřebu, nebo investici s vysokým potenciálem
                        zhodnocení.
                    </Description>
                    <Description>
                        Rekonstrukce v letech 2013 a 2017 zaručují moderní vybavení a spolehlivost, což je v dnešní
                        uspěchané době klíčové. Tato nemovitost je opravdu unikátní a může splnit mnoho vašich přání –
                        ať už hledáte rodinný dům pro vaši vlastní potřebu, nebo investici s vysokým potenciálem
                        zhodnocení.
                    </Description>
                    <Description>
                        Bělkovice-Laštany se mohou pochlubit výbornou občanskou vybaveností, která usnadňuje Váš
                        každodenní život. Nachází se zde školy, školky, obchody, restaurace a další služby, které jsou v
                        dosahu pěšky či krátkou jízdou autem. Díky tomu nemusíte trávit hodiny dojížděním a můžete více
                        času věnovat rodině a svým zálibám. Pro milovníky sportu a aktivního života je v okolí dostatek
                        možností. Můžete si zahrát tenis na místních kurtech, vyrazit na cyklistický výlet nebo si užít
                        oblíbeného sportu na nedalekém sportovním hřišti.
                    </Description>
                </Container>
                <VirtualTour link="https://my.matterport.com/show/?m=89vPzV7XKGv"/>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
