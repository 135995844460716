import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Ponuka from "components/cards/Offer.js"
import Services from "components/features/Services";
import Footer from "components/footers/ContactFooter.js"
import Testimonials from "./components/testimonials/Testimonials";

export default () => (
    <AnimationRevealPage>
      <Hero/>
      <Ponuka/>
      <Services/>
      <Testimonials/>
      <Footer/>
    </AnimationRevealPage>
);
