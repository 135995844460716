import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-1.svg";
import axios from "axios";

const Container = tw.div`relative p-5 m-5 text-center`;

const Heading = tw.h1`font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-primary-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.form`
  ${tw`relative max-w-md text-center mx-auto`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const BASE_URL =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8080"
        : "https://www.neverejne.cz";

export default () => {
  const sendInterestContact = (e) => {
    e.preventDefault();
    const phone = document.getElementById("phoneInput").value;
    axios
    .post(`${BASE_URL}/api/user/features-contact`, phone, {
      headers: {"Content-Type": "text/plain"},
    })
    .then(() => {
      document.getElementById("phoneForm").reset();
    })
    .catch((err) => {
      document.getElementById("phoneForm").reset();
    });
  };

  return (
      <>
        <Container>
          <Heading>
            6 služeb, kterými zajistíme bezstarostný a bleskový prodej Vaší nemovitosti.
          </Heading>
          <Paragraph>
            Zanechte nám Vaše číslo a všechno ostatní nechte na nás.
          </Paragraph>
          <Actions onSubmit={sendInterestContact} id="phoneForm">
            <input
                type="tel"
                placeholder="+420"
                id="phoneInput"
            />
            <button type="submit">Odeslat</button>
          </Actions>
          <DecoratorBlob1/>
        </Container>
      </>
  );
};
