import React from "react";

export default function ({ color }) {
  return (
    <svg viewBox="0 0 512 512" style={{ marginBottom: "10px", padding: "5px" }}>
      <path
        d="M135.91,462.046a42.664,42.664,0,0,0-80.359-11.469A36.3,36.3,0,0,0,51.2,447.94V307.2a8.533,8.533,0,0,0-17.066,0V443.733a34.133,34.133,0,0,0,0,68.267H128a25.6,25.6,0,0,0,7.91-49.954ZM128,494.933H34.133a17.067,17.067,0,1,1,0-34.133c7.962,0,15.036,4.625,17.6,11.52a8.535,8.535,0,0,0,16.529-2.987,25.6,25.6,0,1,1,51.2,0A8.536,8.536,0,0,0,128,477.866a8.534,8.534,0,0,1,0,17.067Z"
        fill={color}
      />
      <path
        d="M503.467,68.267H341.333a8.533,8.533,0,0,0,0,17.066h153.6v184.2l-232.9-232.9a8.592,8.592,0,0,0-12.118,0L17.067,269.525V85.333h93.867a8.536,8.536,0,0,0,8.533-8.533V42.667a8.536,8.536,0,0,0-8.533-8.533H102.4V17.067h51.2V34.134h-8.533a8.536,8.536,0,0,0-8.533,8.533V76.8a8.536,8.536,0,0,0,8.533,8.533h25.6a8.533,8.533,0,1,0,0-17.066H153.6V51.2h8.533a8.536,8.536,0,0,0,8.533-8.533V8.533A8.536,8.536,0,0,0,162.133,0H93.867a8.536,8.536,0,0,0-8.533,8.533V42.666A8.536,8.536,0,0,0,93.867,51.2H102.4V68.266H8.533A8.536,8.536,0,0,0,0,76.8L.034,290.133A8.533,8.533,0,0,0,14.6,296.167L255.974,54.707,497.433,296.166A8.531,8.531,0,0,0,512,290.132V76.8A8.535,8.535,0,0,0,503.467,68.267Z"
        fill={color}
      />
      <path
        d="M477.867,443.733V307.2a8.533,8.533,0,1,0-17.066,0V447.94a36.164,36.164,0,0,0-4.352,2.637,42.664,42.664,0,0,0-80.359,11.469A25.655,25.655,0,0,0,358.4,486.4a25.051,25.051,0,0,0,1.579,8.533H213.333a8.533,8.533,0,1,0,0,17.066H477.866a34.133,34.133,0,1,0,0-68.266Zm0,51.2H384a8.533,8.533,0,0,1,0-17.066,8.536,8.536,0,0,0,8.533-8.533,25.6,25.6,0,0,1,51.2,0,8.535,8.535,0,0,0,16.529,2.987c2.568-6.895,9.643-11.52,17.6-11.52a17.066,17.066,0,1,1,0,34.132Z"
        fill={color}
      />
      <path
        d="M290.133,170.667A34.133,34.133,0,1,0,256,204.8,34.167,34.167,0,0,0,290.133,170.667Zm-51.2,0A17.067,17.067,0,1,1,256,187.734,17.084,17.084,0,0,1,238.933,170.667Z"
        fill={color}
      />
      <path
        d="M418.133,290.133H349.866a8.536,8.536,0,0,0-8.533,8.533v93.867H332.8a8.533,8.533,0,1,0,0,17.066H435.2a8.533,8.533,0,1,0,0-17.066h-8.533V298.666A8.537,8.537,0,0,0,418.133,290.133Zm-8.533,102.4H358.4V358.4h51.2Zm0-51.2H358.4V307.2h51.2Z"
        fill={color}
      />
      <path
        d="M341.333,469.333A8.536,8.536,0,0,0,332.8,460.8H307.2V298.667a8.536,8.536,0,0,0-8.533-8.533H213.334a8.536,8.536,0,0,0-8.533,8.533V460.8H179.2a8.536,8.536,0,0,0-8.533,8.533v34.133a8.533,8.533,0,0,0,17.066,0v-25.6H332.8A8.535,8.535,0,0,0,341.333,469.333Zm-51.2-93.866H281.6a8.533,8.533,0,0,0,0,17.066h8.533V460.8H221.866V307.2h68.267Z"
        fill={color}
      />
      <path
        d="M162.133,290.133H93.867a8.536,8.536,0,0,0-8.533,8.533v93.867H76.8a8.534,8.534,0,0,0,0,17.067H179.2a8.533,8.533,0,1,0,0-17.066h-8.533V298.667A8.537,8.537,0,0,0,162.133,290.133Zm-8.533,102.4H102.4V358.4h51.2Zm0-51.2H102.4V307.2h51.2Z"
        fill={color}
      />
    </svg>
  );
}
