import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Hero from "components/hero/BackgroundAsImage.js";

import Footer from "components/footers/ContactFooter.js";
import MainFeature1 from "components/features/MainFeature.js";
import HomestagingImg from "images/services/homestaging.jpg";
import VirtualTourImg from "images/services/virtual_tour.jpg";
import MarketingImg from "images/services/services_5.jpg";
import LawyerImg from "images/services/lawyer.jpg";
import ContactUsForm from "../components/forms/ContactForm";
import {SectionHeading} from "../components/misc/Headings";
import Testimonials from "../components/testimonials/Testimonials";
import FeaturesContactUs from "../components/hero/FeaturesContactUs";

const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
      <AnimationRevealPage>
        <Hero/>
        <FeaturesContactUs/>
        <MainFeature1
            subheading={<Subheading>UPRAVÍME NEMOVITOST TAK, ŽE SE DO NÍ KAŽDÝ ZAMILUJE</Subheading>}
            heading={<Heading> Homestaging</Heading>}
            imageSrc={HomestagingImg}
            description="Lidé se rozhodují na základě vizuální stránky a emocí, což pro nás dělá z homestagingu neodmyslitelnou součást prezentace. Co to znamená? Důkladná příprava exteriéru a interiéru nemovitosti neboli profesionální aranžování prostorů, které dokonale pozvedne celkový dojem z prodávané nemovitosti. Ta je pak atraktivní pro více potencionálních kupujících, zvýší se její hodnota a urychlí její prodej."
        />
        <MainFeature1
            subheading={<Subheading>PŘIPRAVÍME PREZENTACI HODNOU TITULNÍCH STRÁNEK MAGAZÍNU</Subheading>}
            heading={<Heading> Profesionální prezentace </Heading>}
            textOnLeft={false}
            imageSrc="https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600890693/presentation_tzsg31.jpg"
            description="Kvalitní fotografie jsou pro nás jednou z dalších neodmyslitelných částí prezentace, jelikož pomáhají zaujmout. Stávají se tak rozhodujícím prvkem u potencionálních zájemců o Vaši nemovitost. Proto dbáme na to, aby fotografie byly kvalitní, se smyslem pro detail, daly vyniknout prostoru, světlu a barvám. Klademe také velký důraz na speciální vybavení realitního fotografa a profesionální techniku. Žádné fotografie pořízené běžným mobilem v naší inzerci nenajdete."
        />
        <MainFeature1
            subheading={<Subheading>ZPROPAGUJEME NEMOVITOST TAK, ABY SE PRODALA ANI SE NENADĚJETE</Subheading>}
            heading="Marketing"
            imageSrc={MarketingImg}
            description="Pro každou nemovitost umíme nastavit ideální marketingovou strategii prodeje tak, aby lidé Vaši nemovitost viděli nejen na realitních portálech, ale i na sociálních sítích anebo díky bannerovým kampaním i na celém internetu. Neustále sledujeme trendy v online reklamě a aktivně pracujeme s naší databází potencionálních zájemců, kterým můžeme Vaši nemovitost přímo nabídnout. Nečekáme tak nečinně na poptávku jako většina realitních makléřů, aktivně ji vytváříme!"
        />
        <MainFeature1
            subheading={<Subheading>DĚLÁME PROHLÍDKY, KTERÉ JSOU PRO KUPUJÍCÍ ZÁŽITKEM</Subheading>}
            heading="Prohlídka"
            textOnLeft={false}
            imageSrc={VirtualTourImg}
            description="Občerstvení, playlist na míru nebo třeba příjemná vůně. To vše přispívá k navození té správné atmosféry a přináší pocit, že se cítíte jako doma. Jelikož právě prohlídka nemovitosti je tím nejdůležitějším momentem prodeje, měla by v potencionálním zájemci vyvolat pozitivní emoci a přinést příjemný zážitek. V kombinaci s profesionalitou našich makléřů se navíc celkový dojem z prohlídky ještě umocňuje a zájem o koupi zvyšuje."
        />
        <MainFeature1
            subheading={<Subheading>VYŘEŠÍME VŠE ZA VÁS A VY NEZAPLATÍTE ANI KORUNU NAVÍC</Subheading>}
            heading="Právní poradenství a servis"
            imageSrc={LawyerImg}
            description="Uvědomujeme si důležitost správné smluvní dokumentace, proto je pro nás zajištění právní stránky zprostředkovaných transakcí nadmíru důležité a spolupracujeme pouze s právnickými kancelářemi specializovanými na nemovitosti. Kdybyste měli na výběr mezi tří a pětihvězdičkovým hotelem za stejnou cenu, který byste si vybrali? Odpověď je zřejmá a s realitními kancelářemi je to podobné. Společným cílem je prodej, ale díky našemu individuálnímu přístupu Vás celým procesem provedeme tak, že bude příjemný a bezstarostný. "
        />
        <MainFeature1
            subheading={<Subheading>UŠETŘÍME VÁM ČAS, STAROSTI I PENÍZE</Subheading>}
            textOnLeft={false}
            heading="Financování"
            imageSrc="https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600893029/pexels-pixabay-209224_djgkaz.jpg"
            description="Pro budoucí kupující jsme schopni zajistit zprostředkování financování, pro prodávající to znamená urychlení prodeje. Díky přímé spolupráci se všemi bankovními institucemi na českém trhu dokážeme zároveň zjistit, zda je pro kupujícího financování dosažitelné či nikoliv, což je také z časového hlediska přidaná hodnota naší služby."
        />
        <ContactUsForm/>
        <Testimonials/>
        <Footer/>
      </AnimationRevealPage>
  );
};
