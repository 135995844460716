import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import {SectionHeading} from "components/misc/Headings";
import FiveColumnDark from "../components/footers/ContactFooter";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({headingText = "Zásady ochrany osobních údajů a informace o zpracování osobních údajů"}) => {
  return (
      <AnimationRevealPage>
        <Header/>
        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading>{headingText}</Heading>
            </HeadingRow>
            <Text>
              <p>Tyto Zásady ochrany osobních údajů (dále jen „Zásady“) přijaté ve smyslu nařízení Evropského parlamentu a rady (EU) 2016/679, o ochraně
                fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o
                ochraně osobních údajů) (dále jen „Obecné nařízení“) informují o způsobu a rozsahu zpracování osobních údajů, včetně rozsahu práv subjektů údajů
                souvisejících se zpracováním jejich osobních údajů.</p>

              <h2>1.Správce</h2>
              <p>
                Správcem osobních údajů ve smyslu Obecného nařízení je společnost Neverejne s.r.o., se sídlem Hněvotín 162, PSČ: 783 47, IČO: 07746989, zapsaná v obchodním rejstříku vedeném u Krajského soudu v Ostravě, oddíl C, vložka 77092. </p>
              <p>
                Správce chrání veškeré zpracovávané osobní údaje jako přísně důvěrné a nakládá s nimi v souladu s platnými a účinnými obecně závaznými právními
                předpisy v oblasti ochrany osobních údajů.
              </p>

              <h2>2. Právní základ a účely zpracování osobních údajů</h2>
              <p>
                Správce je obchodní společností vykonávající především zprostředkovatelskou činnost na základě smluvních ujednání a provozující za tím účelem
                originální systémovou platformu.
              </p>
              <p>
                Správce zpracovává osobní údaje:
              </p>
              <ol type='i'>
                <li>
                  na základě souhlasu případně uděleného subjektem údajů,
                </li>
                <li>
                  v souvislosti s plněním smlouvy, jejíž smluvní stranou je subjekt údajů, nebo pro provedení opatření přijatých před uzavřením smlouvy na
                  žádost tohoto subjektu údajů,
                </li>
                <li>
                  pro splnění právních povinností vyplývajících z obecně závazných právních předpisů, a
                </li>
                <li>
                  pro účely oprávněných zájmů správce a případných třetích stran.
                </li>
              </ol>
              <p>Správce zpracovává osobní údaje pro účely:</p>
              <ol type='a'>
                <li>
                  plnění právních povinností správce, zejména řádného vedení veškerých zákonem stanovených evidencí a výkaznictví správce jako obchodní
                  společnosti,
                </li>
                <li>
                  plnění právních povinností správce, zejména řádného vedení veškerých zákonem stanovených evidencí a výkaznictví správce jako obchodní
                  společnosti,
                </li>
                <li>
                  vedení kontaktů svých smluvních partnerů, jejich zástupců a jimi zastoupených třetích osob,
                </li>
                <li>
                  zasílání obchodních sdělení s nabídkou produktů a služeb
                </li>
                <li>
                  při ochraně oprávněných zájmů správce,
                </li>
                <li>
                  při jakékoli komunikaci subjektu údajů se správcem mimo výše uvedený rámec.
                </li>
              </ol>
              <p>
                Oprávněnými zájmy správce jsou zejména zájem na řádném chodu společnosti správce, na plnění uzavřených smluv, a dále zájem na možnosti řádného
                uplatnění nároků z těchto smluv.
              </p>

              <h2>3. Shromažďování a zpracování osobních údajů</h2>
              <p>
                Osobní údaje o subjektech údajů správce získává alternativně (i) přímo od subjektu údajů přímým poskytnutím konkrétního osobního údaje, (ii)
                přímo od subjektu údajů v rámci využití kontaktního formuláře na webových stránkách správce, (iii) nepřímo od třetí osoby.
              </p>
              <p>
                Shromažďovanými a zpracovávanými osobními údaji zejména jsou identifikační údaje (titul, jméno, příjmení, datum narození, IČO, DIČ atd.),
                kontaktní údaje (adresa, telefon, e-mail), finanční údaje (číslo bankovního účtu), údaje o způsobu, jakým subjektu údajů využívá služby správce
                (soubory cookies), IP adresu, jakož i případné další zde výslovně neuvedené údaje, které subjekt údajů správci poskytne.
              </p>
              <p>
                Osobní údaje jsou zpracovávány v listinné i elektronické podobě manuálním a automatizovaným způsobem.
              </p>
              <p>
                Subjekt údajů je povinen poskytovat správci pouze pravdivé a přesné osobní údaje.
              </p>
              <h2>4. Doba uložení osobních údajů</h2>
              <p>
                Osobní údaje jsou správcem zpracovávány po nezbytně nutnou dobu, přinejmenším však po dobu účinnosti smlouvy se subjektem údajů, resp. po dobu
                účinnosti závazkového právního vztahu, v souvislosti s nímž jsou osobní údaje subjektu údajů zpracovávány; déle jsou osobní údaje zpracovávány
                za předpokladu, že to stanoví právní předpis, anebo odůvodňuje-li takové zpracování potřeba či potenciál úplného vypořádání nároků ze
                závazkového právního vztahu uzavřeného mezi správcem a subjektem údajů, resp. třetí osobou, jsou-li v této souvislosti zpracovávány osobní údaje
                subjektu údajů.
              </p>
              <h2>5. Příjemci a zpracovatelé osobních údajů</h2>
              <p>
                S výjimkami ve smyslu čl. 4 odst. 9 Obecného nařízení mohou být příjemci či zpracovateli osobních údajů:
              </p>
              <ol type={"i"}>
                <li>
                  orgány veřejné moci, resp. subjekty pověřené plněním úkolů ve veřejném zájmu;
                </li>
                <li>
                  dodavatelé správce v oblasti služeb informačních systémů (údržba),účetních, daňových, právních a jiných obdobných služeb souvisejících s
                  podnikatelskou činností správce (např. znalci, projektanti apod.);
                </li>
                <li>
                  smluvní partneři správce v rozsahu osobních údajů subjektu údajů, jejichž předání či sdílení s příjemcem je nezbytné pro účely naplnění
                  smluvních ujednání se subjektem údajů, resp. s příjemcem;
                </li>
                <li>
                  další příjemci dle potřeb a pokynů subjektu údajů.
                </li>
              </ol>
              <h2>6. Zabezpečení osobních údajů</h2>
              <p>
                Správce dbá na bezpečnost osobních údajů, které v rámci své činnosti získal.
              </p>
              <p>
                Správce přijal taková organizační a technická opatření, která tyto osobní údaje v elektronické i fyzické podobě s ohledem na závažnost jejich
                zpracování dostatečně ochrání před náhodným nebo protiprávním zničením, ztrátou, pozměňováním či neoprávněným zpřístupněním.
              </p>
              <p>
                Osobní údaje nebudou předávány do třetí země mimo Evropskou unii ani mezinárodní organizaci.
              </p>

              <h1>7. Práva subjektu údajů</h1>
              <p>
                Předpisy na ochranu osobních údajů přiznávají subjektům údajů následující práva:
              </p>
              <ol>
                <li>
                  právo na přístup k osobním údajům – subjekt údajů má právo od správce získat informace o tom, zda zpracovává jeho osobní údaje, a pokud ano,
                  o jaké údaje se jedná a jakým způsobem jsou zpracovávány;
                </li>
                <li>
                  právo na opravu/doplnění osobních údajů – subjekt údajů má právo, aby správce bez zbytečného odkladu opravil na jeho žádost nepřesné
                  osobní údaje, které se ho týkají; neúplné osobní údaje má subjekt údajů právo kdykoli doplnit;
                </li>
                <li>
                  právo na výmaz osobních údajů („právo být zapomenut“) – subjekt údajů má právo, aby správce zlikvidoval osobní údaje, které o subjektu údajů
                  zpracovává, pokud jsou splněny určité podmínky a subjekt údajů o to požádá;
                </li>
                <li>
                  právo na omezení zpracování osobních údajů – subjekt údajů má právo, aby správce v určitých případech omezil zpracování jeho osobních údajů;
                </li>
                <li>
                  právo vznést námitku proti zpracování osobních údajů – subjekt údajů má právo kdykoli vznést námitku proti zpracování, které je založeno na
                  oprávněných zájmech správce, třetí strany nebo je nezbytné pro splnění úkolu prováděného ve veřejném zájmu nebo při výkonu veřejné moci;
                </li>
                <li>
                  právo na přenositelnost osobních údajů – subjekt údajů má právo získat od správce osobní údaje, které správci poskytl, v běžném a strojově
                  čitelném formátu; tyto údaje může následně předat jinému správci; pokud je to technicky možné, má subjekt údajů právo žádat, aby si správci
                  předali osobní údaje mezi sebou;
                </li>
                <li>
                  právo odvolat souhlas se zpracováním osobních údajů – pokud bude správce zpracovávat osobní údaje subjektu údajů na základě jeho souhlasu, má
                  subjekt údajů právo tento svůj souhlas kdykoli odvolat.
                </li>
              </ol>

              <h1>8. Stížnost</h1>
              <p>
                Subjekt údajů, který bude jakkoli nespokojen se zpracováním svých osobních údajů prováděným správcem, může podat stížnost správci písemně na
                adrese Neverejne s.r.o., Hněvotín 162, PSČ: 783 47, nebo elektronickou poštou na adrese info@neverejne.cz, nebo
                Úřadu pro ochranu osobních údajů, Pplk. Sochora 27, 170 00 Praha 7.
              </p>
            </Text>
          </ContentWithPaddingXl>
        </Container>
        <FiveColumnDark/>
      </AnimationRevealPage>
  );
};
