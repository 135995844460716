import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LogoImage from "images/logo-light.svg";
import {ReactComponent as FacebookIcon} from "images/facebook-icon.svg";
import {ReactComponent as InstagramIcon} from "images/instagram-round-line.svg";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";
import axios from "axios";
import {Link as RouterLink} from "react-router-dom";

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between`;

const CompanyColumn = tw.div`text-center md:text-center mb-16 lg:mb-5 w-full lg:w-1/5`;
const ColumnHeading = tw.h5`font-bold uppercase`;

const SubscribeNewsletterColumn = tw(
  CompanyColumn
)`text-center lg:text-center w-full! lg:w-auto!`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0 text-black`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(
  PrimaryButtonBase
)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black`;

const CompanyAddress = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-center`;

const SocialLinksContainer = tw.div`mt-4 text-center lg:text-center`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-500 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-between items-center`;
const CopyrightNotice = tw.div``;
const CompanyInfo = tw.div``;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;

const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://www.neverejne.cz";

export default () => {
  const sendEmailSubscription = (e) => {
    e.preventDefault();
    const email = document.getElementById("emailInput").value;
    axios
      .post(`${BASE_URL}/api/user/newsletter-email`, email, {
        headers: { "Content-Type": "text/plain" },
      })
      .then(() => {
        document.getElementById("emailForm").reset();
      })
      .catch((err) => {
        document.getElementById("emailForm").reset();
      });
  };

  return (
    <Container id="contact_form">
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} />
              <LogoText>Neverejne s.r.o.</LogoText>
            </LogoContainer>
            <CompanyAddress>
              IČO: 07746989 <br />
              Zapsaná v OR vedeném KS <br />v Ostravě, oddíl C, vložka 77092
            </CompanyAddress>
          </CompanyColumn>
          <CompanyColumn>
            <ColumnHeading>Kontakt</ColumnHeading>
            <LinkList>
              <LinkListItem>
                Hněvotín 162, <br />
                783 47 Hněvotín <br />
              </LinkListItem>
              <LinkListItem>
                <Link href="tel:+420 601 101 463"> +420 601 101 463</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:info@neverejne.cz">
                  info@neverejne.cz
                </Link>
              </LinkListItem>
              <SocialLinksContainer>
                <SocialLink href="https://www.facebook.com/komerckyreal">
                  <FacebookIcon />
                </SocialLink>
                <SocialLink href="https://www.instagram.com/komercky_real">
                  <InstagramIcon />
                </SocialLink>
              </SocialLinksContainer>
            </LinkList>
          </CompanyColumn>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Zanechte nám kontakt</ColumnHeading>
              <SubscribeText>
                Chcete-li být mezi prvními, kdo se dozví o novinkách na trhu s
                nemovitostmi, zanechte nám Váš email.
              </SubscribeText>
              <SubscribeForm onSubmit={sendEmailSubscription} id="emailForm">
                <Input
                  type="email"
                  placeholder="Vaše emailová adresa"
                  id="emailInput"
                />
                <SubscribeButton type="submit">Odeslat</SubscribeButton>
              </SubscribeForm>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          <CopyrightNotice>&copy; Copyright 2020, Treact Inc.</CopyrightNotice>
          <CompanyInfo>
            <Link to="/gdpr">
              <RouterLink to="/gdpr">Zásady ochrany osobních údajů
            </RouterLink>
            </Link>
          </CompanyInfo>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
