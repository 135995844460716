export const freeProperties = [
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1706639546/praslavice/1_d9wn82.jpg",
        title: "Stylový a nádherně zařízený rodinný dům",
        areaText: "309",
        locationText: "Přáslavice",
        pricingText: "10.950.000 Kč",
        primaryButtonUrl: "/praslavice",
        dispositionText: "dům",
        monthly: "48.700 Kč",
        state: "free",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1695229401/visnova/1_a1bpvy.jpg",
        title: "Rodinný dům s dobrou dostupnosti do Prahy",
        areaText: "1608",
        locationText: "Višňová",
        pricingText: "6.400.000 Kč",
        primaryButtonUrl: "/visnova",
        dispositionText: "dům",
        monthly: "26.400 Kč",
        state: "free",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1670961717/kostelec/1_og1cxr.jpg",
        title: "Stavební pozemek nedaleko od Prahy",
        areaText: "4669",
        locationText: "Kostelec",
        pricingText: "20.790.000 Kč",
        primaryButtonUrl: "/pozemek-kostelec",
        dispositionText: "pozemek",
        monthly: "85.000 Kč",
        state: "free",
    },
    // {
    //     imageSrc:
    //         "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1646734355/hnevotin/1_y0bywd.jpg",
    //     title: "Exkluzivní rodinný dům na vyhledávané adrese",
    //     areaText: "165",
    //     locationText: "Hněvotín, Olomouc",
    //     pricingText: "15.990.000 Kč",
    //     primaryButtonUrl: "/hnevotin",
    //     dispositionText: "5+kk",
    //     monthly: "65.000 Kč",
    //     state: "free",
    // },
    // {
    //     imageSrc:
    //         "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1639416118/utechov/1_psebdm.jpg",
    //     title: "Výrobně skladovací areál nedaleko Moravské Třebové",
    //     areaText: "4 233",
    //     locationText: "Útěchov, Moravské Třebové",
    //     pricingText: "na vyžádání",
    //     primaryButtonUrl: "/areal-utechov",
    //     dispositionText: "areal",
    //     monthly: "na vyžádání",
    //     state: "free",
    // }
];

export const reservedProperties = []

export const soldProperties = [
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1695228617/belkovice/1_xkgrqw.jpg",
        title: "Prostorný dům v Bělkovicích-Lašťanech s velkým potenciálem",
        areaText: "559",
        locationText: "Bělkovice",
        pricingText: "6.990.000 Kč",
        primaryButtonUrl: "/belkovice",
        dispositionText: "dům",
        monthly: "46.500 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1671011220/kostelec-dum/1_b7ust1.jpg",
        title: "Rozlehlý dům na Černokostelecku",
        areaText: "484",
        locationText: "Kostelec",
        pricingText: "12.490.000 Kč",
        primaryButtonUrl: "/dum-kostelec",
        dispositionText: "dům",
        monthly: "59.900 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1667414882/rokycanova/1_kwflud.jpg",
        title: "Moderní a reprezentativní kancelářské prostory",
        areaText: "114",
        locationText: "Rokycanova, Olomouc",
        pricingText: "7.490.000 Kč",
        primaryButtonUrl: "/rokycanova",
        dispositionText: "5+kk",
        monthly: "35.700 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1644217454/bystrc/1_dj7jo9.jpg",
        title: "Dvoupokojový byt v okrajové části Brna",
        areaText: "60",
        locationText: "Brno, Bystrc",
        pricingText: "5.000.000 Kč",
        primaryButtonUrl: "/bystrc",
        dispositionText: "2+1",
        monthly: "26.800 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1618234864/chata/1_whbxwx.jpg",
        title: "Chata s pohádkovou atmosférou a velkorysým pozemkem",
        areaText: "120",
        locationText: "Valašské Klobouky, okres Zlín",
        pricingText: "3.900.000 Kč",
        primaryButtonUrl: "/chata-valasske-klobouky",
        dispositionText: "chata",
        monthly: "12.450 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1654679841/prerovska/1new_c9oevu.jpg",
        title: "Moderní, světlý byt s pohodovou atmosférou",
        areaText: "64",
        locationText: "Olomouc – Holice",
        pricingText: "4.600.000 Kč",
        primaryButtonUrl: "/prerovska",
        dispositionText: "2+1",
        monthly: "21.300 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1652689839/medlanky-byt/1_vedv0e.jpg",
        title: "Útulný jednopokojový byt v Brně – Medlánkách",
        areaText: "26",
        locationText: "Medlánky, Brno",
        pricingText: "3.520.000 Kč",
        primaryButtonUrl: "/medlanky",
        dispositionText: "1+kk",
        monthly: "15.800 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1646992695/stepanov/1_ohraničení_xb154o.jpg",
        title: "Rozsáhlý, rovinatý pozemek ve Štěpánově",
        areaText: "1024",
        locationText: "Štěpánov",
        pricingText: "3.490.000 Kč",
        primaryButtonUrl: "/stepanov",
        dispositionText: "pozemek",
        monthly: "15.100 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1644217388/rezacova/1_nkt0rd.png",
        title: "Světlý a prostorný byt v širším centru Olomouce",
        areaText: "52",
        locationText: "Olomouc, Lazce",
        pricingText: "3.550.000 Kč",
        primaryButtonUrl: "/rezacova",
        dispositionText: "2+1",
        monthly: "15.800 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1639424242/hustopece/1_Byt-31-v-Hustopecich-Kitchen_qh7by5.jpg",
        title: "Vkusně zrekonstruovaný byt v příjemné lokalitě",
        areaText: "76",
        locationText: "Hustopeče, okres Brno",
        pricingText: "5.890.000 Kč",
        primaryButtonUrl: "/hustopece",
        dispositionText: "3+1",
        monthly: "26.500 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600711434/frydecka/areal_2_mhrkta.jpg",
        title: "Jedinečný víceúčelový areál v obci Vratimov",
        areaText: "919",
        locationText: "Frýdecká, Vratimov",
        pricingText: "8.500.000 Kč",
        primaryButtonUrl: "/frydecka",
        dispositionText: "areál",
        monthly: "30.000 Kč",
        state: "sold"
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1639422609/wolkerova/1_Byt-21-v-Olomouci-ul-Wolkerova-27818-Living-Room_wzopqn.jpg",
        title: "Prosluněný byt v žádané lokalitě Olomouce",
        areaText: "52",
        locationText: "Wolkerova, Olomouc",
        pricingText: "3.200.000 Kč",
        primaryButtonUrl: "/wolkerova",
        dispositionText: "2+1",
        monthly: "14.400 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1639418865/medlanky/1_Sni%CC%81mek_obrazovky_2021-09-21_v_15.53.37_ijq3gq.png",
        title: "Pozemek v  brněnské zahrádkářské oblasti\n",
        areaText: "801",
        locationText: "Medlánky, Brno",
        pricingText: "1.500.000 Kč",
        primaryButtonUrl: "/medlanky",
        dispositionText: "zahrada",
        monthly: "6.750 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1620936286/janskeho/1_reknfh.jpg",
        title: "Příjemný byt s nadstandardní občanskou vybaveností",
        areaText: "74",
        locationText: "Janského, Praha - Stodůlky",
        pricingText: "6.990.000 Kč",
        primaryButtonUrl: "/janskeho",
        dispositionText: "3+1",
        monthly: "21.200 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1620936286/hybesova/1_d5jnsx.jpg",
        title: "Moderní a elegantní byt s perfektní dostupností",
        areaText: "84",
        locationText: "Hybešova, Brno - Staré Brno",
        pricingText: "7.700.000 Kč",
        primaryButtonUrl: "/hybesova",
        dispositionText: "3+1",
        monthly: "23.350 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1616061006/polom/Polom_11_-_titulna_qzgz9v.jpg",
        title: "Rodinný dům s obrovským pozemkem",
        monthly: "6.100 Kč",
        dispositionText: "4+kk",
        areaText: "147",
        locationText: "Polom u Hranic",
        pricingText: "1.990.000  Kč",
        primaryButtonUrl: "/polom",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1616056279/kosmonautu/Obyvak_01_-_tituna-min_zpnuoo.jpg",
        title: "Prosluněný byt v žádané lokalitě Olomouce",
        monthly: "9.100 Kč",
        dispositionText: "2+1",
        areaText: "58",
        locationText: "Třída Kosmonautů, Olomouc",
        pricingText: "2.990.000  Kč",
        primaryButtonUrl: "/kosmonautu",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600711434/hostin/1_nchlol.jpg",
        title: "Rodinný dům vhodný ke kompletní rekonstrukci",
        areaText: "120",
        locationText: "Hostín u Vojkovic",
        pricingText: "3.000.000 Kč",
        primaryButtonUrl: "/hostin",
        dispositionText: "4+kk",
        monthly: "9.100 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600711434/aloiserasina2/01_Obývák_kuchyň_PANO_01_-_upr_ez15op.jpg",
        title: "Vzdušný byt s výjimečným designem",
        areaText: "83",
        locationText: "Aloise Rašína, Olomouc",
        pricingText: "5.800.000  Kč",
        primaryButtonUrl: "/aloise-rasina-2",
        dispositionText: "3+kk",
        monthly: "17.520 Kč",
        state: "sold",
    }, {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600711434/vrane/1_bfokld.jpg",
        title: "Rodinný dům v klidné a atraktivní lokalitě",
        areaText: "127",
        locationText: "Vrané nad Vltavou",
        pricingText: "7.400.000  Kč",
        primaryButtonUrl: "/rodinny-dum-vrane",
        dispositionText: "5+kk",
        monthly: "22.350 Kč",
        state: "sold",
    },
    {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600954296/Kuchy%C5%88_Ob%C3%BDv%C3%A1k_02_-_titulna_ioyy9t.jpg",
        title: "Charismatický  byt ve vilové čtvrti",
        monthly: "38.145 Kč",
        dispositionText: "6+kk",
        areaText: "167",
        locationText: "Černopolní, Olomouc",
        pricingText: "12.929.000 Kč",
        primaryButtonUrl: "/cernopolni",
        state: "sold",
    }, {
        imageSrc:
            "https://res.cloudinary.com/dg47fbat9/image/upload/q_auto:low/v1600888980/01_vcaqvt.jpg",
        title: "Moderní byt v atraktivní části Olomouce",
        monthly: "13.750  Kč",
        dispositionText: "3+kk",
        areaText: "83",
        locationText: "Aloise Rašína, Olomouc",
        primaryButtonUrl: "/aloise-rasina",
        pricingText: "4.650.000  Kč",
        state: "sold",
    }
];
