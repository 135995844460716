import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                    card = {
                        title: "Rodinný dům s dobrou dostupnosti do Prahy",
                        subtitle:
                            "Nabízíme Vám prostorný rodinný dům s perfektní dispozicí v obci Višňová.",
                        monthly: "26.400 Kč",
                        price: "6.400.000 Kč",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/1_a1bpvy.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/1_a1bpvy.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/2_tuz8xd.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/2_tuz8xd.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/3_i210qp.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/3_i210qp.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/4_ynitff.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/4_ynitff.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/5_p1uf4x.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/5_p1uf4x.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/6_hdlndy.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/6_hdlndy.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/7_lh0ae0.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/7_lh0ae0.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/8_ezdbxv.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/8_ezdbxv.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/9_bahxfr.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/9_bahxfr.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/10_jkrcx3.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/10_jkrcx3.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/11_img1ws.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/11_img1ws.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/12_wymrxz.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/12_wymrxz.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/13_sy4rce.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/13_sy4rce.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/14_pwrhne.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/14_pwrhne.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/15_gtjwyu.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/15_gtjwyu.jpg",
                            },
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/16_zjajuw.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/16_zjajuw.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1695229401/visnova/17_ooduyk.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1695229401/visnova/17_ooduyk.jpg",
                            }
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>{card.subtitle}</SubHeading>
                            <Price>{card.price}</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                                / měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <Link href="tel:601 101 463">601 101 463</Link>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Momentálně je dům pronajatý na DOBU URČITOU cca 18 let. Je vhodný pro investora. Pro více
                        informací kontaktujte našeho realitního makléře.
                    </Description>
                    <Description>
                        Višňová je malá obec, nacházející se v blízkosti Příbrami, malebného městečka s bohatou historií
                        a kulturním dědictvím.
                    </Description>
                    <Description>
                        Tento nádherný dům o užitné ploše 184 metrů čtverečních a pozemek o rozloze 1608 metrů
                        čtverečních kombinuje moderní pohodlí s útulnou atmosférou
                    </Description>
                    <Description>
                        Po vstupu do domu Vás přivítá prostorná chodba, která vede do obývacího pokoje. Díky velkým
                        oknům zde proudí do interiéru hojnost světla, což vytváří příjemnou a teplou atmosféru. Kuchyně
                        je propojena s obývacím pokojem. Dále zde najdete pokoj s vlastní koupelnou a toaletou.
                    </Description>
                    <Description>
                        Na druhém patře tento dům nabízí další dvě místnosti a také malou koupelnu.
                    </Description>
                    <Description>
                        Velkou výhodou je terasa, která umožňuje užívat si slunečného počasí a zároveň se těšit z
                        krásného výhledu na okolní přírodu. Zde můžete relaxovat nebo si užívat romantické chvíle s
                        výhledem na západ slunce.
                    </Description>
                    <Description>
                        Dům disponuje prostorným podzemním prostorem, kde se nachází technické zázemí pro vytápění a
                        možnosti uskladnění nebo dokonce vytvoření vlastního vinného sklepa.
                    </Description>
                    <Description>
                        Pro milovníky přírody je zde rozlehlá zahrada, kde si můžete vytvořit vlastní zahradní ráj a
                        pěstovat květiny či zeleninu.
                    </Description>
                    <Description>
                        Navzdory tomu, že se nacházíte v malebné vesnici, je cesta do Prahy pohodlná a rychlá. Díky
                        vynikající dopravní infrastruktuře a rychlostní silnici můžete dorazit do centra Prahy za
                        pouhých 45 minut. To je ideální pro ty, kteří chtějí mít výhody velkoměsta na dosah a přitom si
                        užívat klidného venkovského života.
                    </Description>
                    <Description>
                        V obci Višňová se v současnosti aktivně staví mnoho nových domů, což z ní činí perspektivní a
                        rostoucí oblast.
                    </Description>
                </Container>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
