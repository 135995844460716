import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                    card = {
                        title: "Stavební pozemek nedaleko od Prahy",
                        subtitle:
                            "Nabízíme k prodeji pozemek o výměře 4 669 m2 ve okrajové části města Kostelec nad Černými Lesy",
                        monthly: "85.000 Kč",
                        price: "20.790.000 Kč",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1670961717/kostelec/1_og1cxr.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1670961717/kostelec/1_og1cxr.jpg",
                            },{
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1670961717/kostelec/2_hfuges.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1670961717/kostelec/2_hfuges.jpg",
                            },{
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1670961717/kostelec/3_irijar.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1670961717/kostelec/3_irijar.jpg",
                            },{
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1670961717/kostelec/4_g9brwh.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1670961717/kostelec/4_g9brwh.jpg",
                            },{
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1670961717/kostelec/5_o6r02l.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1670961717/kostelec/5_o6r02l.jpg",
                            },
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>{card.subtitle}</SubHeading>
                            <Price>{card.price}</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                                / měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <a href="tel:601 101 463"> 601 101 463</a>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Nabízíme k prodeji pozemek o výměře 4 669 m2 ve <b>okrajové části města Kostelec nad Černými
                        Lesy.</b> Pozemek je oplocen a celý zatravněn s několika ovocnými stromy. Parcela je aktuálně v KN vedená
                        jako ovocný sad, <b>lze však požádat o změnu a pozemek použít na bytovou výstavbu.</b> Přístup je možný
                        přes <b>zpevněnou komunikaci, přípojky na inženýrské sítě jsou na hranici pozemku.</b>
                    </Description>
                    <Description>
                        Oblast Černokostelecka i přes blízkost Prahy je nazývána plícemi středních Čech a je <b>vyhledávanou
                        rekreační oblastí.</b> Okolní lesy, přírodní rezervace a místní arboretum jsou ideálním místem k
                        procházkám či projížďkám na kole. Ve městě najdete také <b>bohatou občanskou vybavenost</b> – obchody,
                        školy, školky, lékaři apod.
                    </Description>
                </Container>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
