import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                    card = {
                        title: "Rozlehlý dům na Černokostelecku",
                        tourLink: "https://my.matterport.com/show/?m=FX2XZ2AjHhb",
                        subtitle:
                            "Nabízíme k prodeji dům o podlahové ploše 484 m2 a zahradou o výměře 3 240 m2 v obci Kostelec nad Černými lesy, nedaleko Prahy.",
                        monthly: "59.900 Kč",
                        price: "12.490.000 Kč",
                        images: [
                            {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/1_b7ust1.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/1_b7ust1.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/2_og8hrs.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/2_og8hrs.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/3_b9ipw0.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/3_b9ipw0.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/4_z1ajjz.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/4_z1ajjz.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/5_jxonqa.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/5_jxonqa.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/6_pwgxd2.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/6_pwgxd2.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/7_it3sb7.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/7_it3sb7.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/8_ovuvjv.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/8_ovuvjv.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/9_akxga8.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/9_akxga8.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/10_sgcqx3.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/10_sgcqx3.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/11_mulihf.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/11_mulihf.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/12_l96ifo.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/12_l96ifo.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/13_sdxbol.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/13_sdxbol.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/14_pszc4u.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/14_pszc4u.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/15_w8wfr0.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/15_w8wfr0.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/16_tas9g4.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/16_tas9g4.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/17_voetin.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/17_voetin.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/18_zevpdc.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/18_zevpdc.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/19_abc8hn.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/19_abc8hn.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/20_fjl7ct.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/20_fjl7ct.jpg",
                            }, {
                                original:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1671011220/kostelec-dum/21_zl79tk.jpg",
                                thumbnail:
                                    "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1671011220/kostelec-dum/21_zl79tk.jpg",
                            },
                        ],
                    },
                }) => {
    return (
        <>
            <div
                style={{
                    paddingTop: 15,
                    paddingBottom: 10,
                    paddingLeft: 30,
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    height: "10rem",
                    background:
                        "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
                    color: "white",
                }}
            >
                <Header links={navLinks}/>
            </div>

            <ImageGallery
                showBullets
                showPlayButton={false}
                showThumbnails={false}
                items={card.images}
            />
            <AnimationRevealPage>
                <Container>
                    <TwoColumn>
                        <TitleColumn>
                            <Heading>{card.title}</Heading>
                            <SubHeading>{card.subtitle}</SubHeading>
                            <Price>{card.price}</Price>
                            <MonthlyDescription>
                                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                                / měsíčně
                            </MonthlyDescription>
                        </TitleColumn>
                        <PhoneColumn textOnLeft={false}>
                            <IconWithText>
                                <IconContainer>
                                    <PhoneIcon/>
                                </IconContainer>
                                <ContactDescription>
                                    <Link href="tel:601 101 463">601 101 463</Link>
                                </ContactDescription>
                            </IconWithText>
                        </PhoneColumn>
                    </TwoColumn>
                    <Description>
                        Jedná se o volně stojící objekt, který byl původně využíván jako dům pro seniory, je <b>podsklepen a má 3
                        nadzemní podlaží.</b> V přízemí najdete kancelářské prostory – celkově 5 samostatných kanceláří a velkou
                        kuchyni se spižírnou. V 1. patře pak 7 pokojů a nově vybudované sociální zařízení (sprchy, toalety), ve 2.
                        patře se nachází 3 podkrovní pokoje a 3 koupelny. Pro pohyb mezi patry lze využít schodiště nebo <b>výtah,</b>
                        který tak zajišťuje zcela bezbariérový přístup.
                    </Description>
                </Container>
                <VirtualTour link={card.tourLink}/>
                <Container>
                    <Description>
                        Vytápění je zde řešeno <b>kotlem na uhlí,</b> kotelna se nachází v suterénu domu.
                    </Description>
                    <Description>
                        Objekt je napojen na městský <b>vodovodní řád a elektrickou energii, kanalizace je řešena vlastní jímkou,</b>
                        přípojka na veřejnou kanalizaci je na hranici pozemku.
                    </Description>
                    <Description>
                        Dle územního plánu lze nemovitost využít jako budovu <b>občanského vybavení</b> - zařízení veřejné správy,
                        školská zařízení, zdravotnická zařízení, sociální zařízení, kulturní zařízení, církevní zařízení, budovu
                        dopravní a technické infrastruktury či ubytovací zařízení do kapacity 50 lůžek, obchodní zařízení nebo
                        zařízení služeb.
                    </Description>
                    <Description>
                        Na pozemku je také vedlejší stavba s <b>garáží,</b> která původně sloužila jako prádelna, takže je zde zavedena
                        elektřina.
                    </Description>
                    <Description>
                        Oblast Černokostelecka i přes blízkost Prahy je nazývána <b>plícemi středních Čech</b> a je vyhledávanou
                        rekreační oblastí. Okolní lesy, přírodní rezervace a místní arboretum jsou ideálním místem k procházkám či
                        projížďkám na kole. Ve městě najdete také bohatou občanskou vybavenost – obchody, školy, školky, lékaři
                        apod.
                    </Description>
                </Container>
                <ContactForm subject={card.title}/>
                <Footer/>
            </AnimationRevealPage>
        </>
    );
};
