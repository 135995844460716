import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import {navLinks} from "components/misc/NavLinks";
import VirtualTour from "../components/misc/VirtualTour";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
    SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
                  card = {
                    title: "Stylový a nádherně zařízený rodinný dům",
                    tourLink: "https://my.matterport.com/show/?m=m5pDMiphLAr",
                    images: [
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/1_d9wn82.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/1_d9wn82.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/2_pbx1b5.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/2_pbx1b5.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/3_ts2rl3.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/3_ts2rl3.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/4_yr7kb5.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/4_yr7kb5.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/5_chj17n.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/5_chj17n.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/6_apiumk.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/6_apiumk.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/7_n9dpdl.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/7_n9dpdl.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/8_gd0qvq.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/8_gd0qvq.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/9_ilzprz.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/9_ilzprz.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/10_yyvjbk.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/10_yyvjbk.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/11_l2llih.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/11_l2llih.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/12_xosgco.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/12_xosgco.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/13_tvg00f.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/13_tvg00f.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/14_yy3q2p.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/14_yy3q2p.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/15_phnb2e.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/15_phnb2e.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/16_scqhww.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/16_scqhww.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/17_w1ocbx.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/17_w1ocbx.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/18_esxmgg.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/18_esxmgg.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/19_fgaiz8.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/19_fgaiz8.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/20_kab87v.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/20_kab87v.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/21_k1izx5.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/21_k1izx5.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/22_ubmcpq.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/22_ubmcpq.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/23_k9oggx.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/23_k9oggx.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/24_lyub9n.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/24_lyub9n.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/25_utdqmb.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/25_utdqmb.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/26_vxt2h6.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/26_vxt2h6.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/27_ccskqb.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/27_ccskqb.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/28_y3kfu7.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/28_y3kfu7.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/29_p70pen.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/29_p70pen.jpg",
                      },
                      {
                        original:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1706639546/praslavice/30_cq1rzc.jpg",
                        thumbnail:
                            "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1706639546/praslavice/30_cq1rzc.jpg",
                      },
                    ],
                  },
                }) => {
  return (
      <>
        <div
            style={{
              paddingTop: 15,
              paddingBottom: 10,
              paddingLeft: 30,
              position: "absolute",
              zIndex: 10,
              width: "100%",
              height: "10rem",
              background:
                  "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
              color: "white",
            }}
        >
          <Header links={navLinks}/>
        </div>

        <ImageGallery
            showBullets
            showPlayButton={false}
            showThumbnails={false}
            items={card.images}
        />
        <AnimationRevealPage>
          <Container>
            <TwoColumn>
              <TitleColumn>
                <Heading>{card.title}</Heading>
                <SubHeading>
                  Nabízíme k prodeji dům v malebné obci Přáslavice o výměře 160 m<sup>2</sup> s pozemkem o výměře 309 m<sup>2</sup>
                </SubHeading>
                <Price>10 950 000 Kč</Price>
                <MonthlyDescription>
                  splátka hypotéky <PricingMonthly>48.700 Kč</PricingMonthly> /
                  měsíčně
                </MonthlyDescription>
              </TitleColumn>
              <PhoneColumn textOnLeft={false}>
                <IconWithText>
                  <IconContainer>
                    <PhoneIcon/>
                  </IconContainer>
                  <ContactDescription>
                    <Link href="tel:601 101 463">601 101 463</Link>
                  </ContactDescription>
                </IconWithText>
              </PhoneColumn>
            </TwoColumn>
            <Description>
              Toužíte po útulném a rodinném domově, který nabízí komfort a pohodu? Máme pro vás skvělou nabídku!
              Představujeme vám stylový a nádherně zařízený rodinný dům s užitnou plochou 160 m² a pozemkem o velikosti
              309 m², který se nachází v malebné obci Přáslavice. Toto místo je idylickou kombinací klidného venkovského
              prostředí s perfektní dostupností do Olomouce.
            </Description>
            <Description>
              Dům je stylový a kvalitně zařízený a je nabízen k prodeji kompletně vybavený. Je dvojpodlažní – v přízemí
              najdete vstupní chodbu propojenou s prostornou garáží, která zaručuje bezproblémové parkování i pro větší
              vozidla. Hlavní část přízemí tvoří otevřený a prosvětlený prostor, který spojuje obývací pokoj, jídelní
              část a kuchyni. Tento prostor má přímý vstup na terasu a do zahrady. Neodmyslitelnou hřejivou součástí
              tohoto domu jsou krbová kamna, u kterých si při zvuku praskajícího dřeva můžete odpočinout a zapomenout na
              všechny starosti. V prvním patře se dále nachází koupelna se sprchovým koutem a toaletou a technická
              místnost, která slouží i jako úložný prostor.
            </Description>
            <Description>
              Horní patro nabízí tři neprůchozí pokoje, samostatnou šatnu a prostornou koupelnu se sprchovým koutem a
              vanou. Praktickou vychytávkou je vkusně zabudovaná prádelna.
            </Description>
            <Description>
              Dům je vybaven úspornými technologiemi, díky kterým jsou v domě velmi nízké provozní náklady. Svědčí o tom
              i to, že tento dům patří do energetické třídy B.
            </Description>
            <Description>
              S celkovým pozemkem 309 m² máte dostatek prostoru pro venkovní posezení, zahradní grilování nebo jen pro
              odpočinek na čerstvém vzduchu. Součástí je taky zahradní domek, který poslouží jako skvělý úložný prostor
              na zahradní vybavení.
            </Description>
            <Description>
              <SubHeading>HLAVNÍ BENEFITY DOMU:</SubHeading>
              <ul style={{listStyle: "disc"}}>
                <li>moderní vybavení a nízké náklady na bydlení,</li>
                <li>do detailů vkusně zařízený dům,</li>
                <li>interiér denní zóny zpracovaný designerem,</li>
                <li>výborná lokalita – blízko přírody a nedaleko Olomouce,</li>
                <li>množství osobního a úložného prostoru,</li>
                <li>prostorná garáž a možnost parkování před domem,</li>
                <li>terasa s pergolou,</li>
                <li>nábytek na míru,</li>
                <li>venkovní žaluzie,</li>
                <li>designová svítidla a kuchyňská linka na míru s kvalitními spotřebiči,</li>
                <li>podlahové topení,</li>
                <li>klimatizace a prosvětlený interiér,</li>
                <li>optické připojení.</li>
              </ul>
            </Description>
            <Description>
              Obec Přáslavice se může pochlubit výbornou občanskou vybaveností, včetně školy, školky, obchodů,
              restaurací a dalších služeb, které jsou v dosahu pěšky nebo krátkou jízdou autem. Sportovní nadšenci ocení
              dostatek možností pro aktivní odpočinek, jako jsou cyklostezky, rozhledna či sportovní hřiště.
            </Description>
            <Description>
              Nenechte si ujít šanci na život ve vysněném domě. Pro více informací nebo domluvení prohlídky nás
              neváhejte kontaktovat. Těšíme se na vaši návštěvu a věříme, že se zde budete cítit jako doma.
            </Description>
          </Container>
          <VirtualTour link={card.tourLink}/>
          <ContactForm subject={card.title}/>
          <Footer/>
        </AnimationRevealPage>
      </>
  );
};
