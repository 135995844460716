import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
  SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
  card = {
    id: 1,
    title: "Výrobně skladovací areál nedaleko Moravské Třebové",
    subtitle: "na vyžádání",
    price: "na vyžádání",
    monthly: "na vyžádání",
    images: [
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/1_psebdm.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/1_psebdm.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/2_qaucw2.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/2_qaucw2.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/3_yfbzzc.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/3_yfbzzc.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/4_exoe8x.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/4_exoe8x.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/5_pojjgz.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/5_pojjgz.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/6_xe7lo7.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/6_xe7lo7.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/7_jvmcu6.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/7_jvmcu6.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/8_nnkxrt.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/8_nnkxrt.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/9_tcwkct.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/9_tcwkct.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/10_a1xfoi.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/10_a1xfoi.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/11_ykvrg0.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/11_ykvrg0.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/12_fi805l.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/12_fi805l.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/13_l5ndea.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/13_l5ndea.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/14_1_zqaabo.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/14_1_zqaabo.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/15_yn0izi.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/15_yn0izi.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/16_oh9xhs.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/16_oh9xhs.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/17_gnpbu3.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/17_gnpbu3.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/18_rtqmlk.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/18_rtqmlk.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/bathroom_xe2g0c.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/bathroom_xe2g0c.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/20_tk6lcs.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/20_tk6lcs.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/21_b0jwoz.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/21_b0jwoz.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1639416118/utechov/22_fubew8.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1639416118/utechov/22_fubew8.jpg",
      },
    ],
  },
}) => {
  return (
    <>
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 30,
          position: "absolute",
          zIndex: 10,
          width: "100%",
          height: "10rem",
          background:
            "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
          color: "white",
        }}
      >
        <Header links={navLinks} />
      </div>

      <ImageGallery
        showBullets
        showPlayButton={false}
        showThumbnails={false}
        items={card.images}
      />
      <AnimationRevealPage>
        <Container>
          <TwoColumn>
            <TitleColumn>
              <Heading>{card.title}</Heading>
              <SubHeading>
                celková plocha 4 233m<sup>2</sup>, Útěchov
              </SubHeading>
              <Price>{card.price}</Price>
              {/*<MonthlyDescription>splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly> /*/}
              {/*    měsíčně</MonthlyDescription>*/}
            </TitleColumn>
            <PhoneColumn textOnLeft={false}>
              <IconWithText>
                <IconContainer>
                  <PhoneIcon />
                </IconContainer>
                <ContactDescription>
                  <Link href="tel:601 101 463">601 101 463</Link>
                </ContactDescription>
              </IconWithText>
            </PhoneColumn>
          </TwoColumn>
          <Description>
            Nabízíme k prodeji výrobně skladovací halu včetně administrativního
            zázemí v obci Útěchov, nedaleko Moravské Třebové. Celková plocha
            pozemků je 4 233 m2, z čehož{" "}
            <b>
              1 225 m2 náleží hale, 250 m2 kancelářské budově, 2 828 m2 je
              manipulační plocha a 180 m2 obslužná komunikace.
            </b>
          </Description>
          <Description>
            <ul style={{ listStyle: "disc" }}>
              <SubHeading>Výrobní a skladovací hala</SubHeading>
              <li>železobetonová konstrukce,</li>
              <li>opláštění a střecha pur panely,</li>
              <li>výška uvnitř z hlavy cca 10-11 m po vazbu,</li>
              <li>podlahy litý zátěžový beton,</li>
              <li>
                hala rozdělena zateplenou příčkou na vytápěnou a nevytápěnou
                část se dvěma vjezdy
              </li>
              <li>
                vnitřní vrata 4x4 m posuvné, venkovní elektrické rolovací cca
                3,5 x 4 m,
              </li>
              <li>na halu navazuje velká rampa,</li>
              <li>
                součásti je i mostový jeřáb, který se nachází v nevytápěné části
                haly,
              </li>
              <li>
                topení je na tuhá paliva – automat, v hale jsou kalolifery,
              </li>
              <li>vlastní trafostanice,</li>
              <li>obsahuje betonovou rampu.</li>
            </ul>
          </Description>

          <Description>
            <ul style={{ listStyle: "disc" }}>
              <SubHeading>Kancelářská budova</SubHeading>
              <li>budova je zateplená,</li>
              <li>plastová okna,</li>
              <li>střecha pur panel,</li>
              <li>ohřev vody el.bojlery,</li>
              <li>topení el. přímotopy.</li>
            </ul>
            <br />V přízemí najdete kuchyňku, kancelář a dvě šatny včetně WC a
            sprchy. V prvním patře se nacházejí dva byty 1+1, které je možné
            využít i jako kancelář. Oba byty mají vlastní koupelnu a WC.
          </Description>

          <Description>
            <ul style={{ listStyle: "disc" }}>
              <SubHeading>Areál</SubHeading>
              <li>
                před kancelářskou budovou je parkoviště se zámkovou dlažbou,
              </li>
              <li>
                3D oplocení a vstupní brána (brána je z jedné poloviny na
                dálkové ovládání a z druhé na pevné, umožňuje při plném otevření
                vjezd, zacouvání a vytočení kamionů),
              </li>
              <li>
                v čele a po straně kancelářské budovy a haly je chodník a
                nachystané kufry (1 m plánovaná celková skladby, makadam,
                32štěrk, chybí zámková) - plocha cca 450 m2,
              </li>
              <li>za halou pouze sečené,</li>
              <li>
                menší skladovací plocha se zámkovou dlažbou u zadních vrat.
              </li>
            </ul>
          </Description>
          <Description>
            Velkou výhodou je, že areál tvoří kompaktní obdélník s možností
            rozšíření a přistavění dalších budov dle potřeby využití. Celý areál
            se nachází hned u sjezdu ze silnice 35.
          </Description>
          <Description>
            V případě zájmu nás neváhejte kontaktovat, rádi se s vámi domluvíme
            na prohlídce.
          </Description>
        </Container>
        <ContactForm subject={card.title} />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
