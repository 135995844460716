import React from "react";

export default function ({ color }) {
  return (
    <svg viewBox="0 0 512 512" style={{ marginBottom: "10px", padding: "5px" }}>
      <g transform="translate(1 1)">
        <path
          d="M502.467,493.933H459.8V50.2h17.067c5.12,0,8.533-3.413,8.533-8.533V7.533c0-5.12-3.413-8.533-8.533-8.533H33.133C28.013-1,24.6,2.413,24.6,7.533V41.666c0,5.12,3.413,8.533,8.533,8.533H50.2V493.932H7.533c-5.12,0-8.533,3.413-8.533,8.533S2.413,511,7.533,511H502.467c5.12,0,8.533-3.413,8.533-8.533S507.587,493.933,502.467,493.933ZM41.667,16.067H468.334V33.134H41.667ZM212.333,493.933V357.4h34.133V493.933H212.333Zm51.2,0V357.4h34.133V493.933H263.533Zm51.2,0V348.867c0-5.12-3.413-8.533-8.533-8.533H203.8c-5.12,0-8.533,3.413-8.533,8.533V493.934h-128V50.2H442.734V493.933Z"
          fill={color}
        />
        <path
          d="M229.4,84.333H92.867c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533H229.4c5.12,0,8.533-3.413,8.533-8.533V92.867C237.933,87.747,234.52,84.333,229.4,84.333ZM101.4,101.4h51.2v51.2H101.4V101.4Zm119.467,51.2h-51.2V101.4h51.2Z"
          fill={color}
        />
        <path
          d="M169.667,340.333h-76.8c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533h76.8c5.12,0,8.533-3.413,8.533-8.533V348.866C178.2,343.747,174.787,340.333,169.667,340.333ZM161.133,408.6H101.4V357.4h59.733Z"
          fill={color}
        />
        <path
          d="M417.133,340.333h-76.8c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533h76.8c5.12,0,8.533-3.413,8.533-8.533V348.866C425.667,343.747,422.253,340.333,417.133,340.333ZM408.6,408.6H348.867V357.4H408.6Z"
          fill={color}
        />
        <path
          d="M417.133,84.333H280.6c-5.12,0-8.533,3.413-8.533,8.533v68.267c0,5.12,3.413,8.533,8.533,8.533H417.134c5.12,0,8.533-3.413,8.533-8.533V92.867C425.667,87.747,422.253,84.333,417.133,84.333Zm-128,17.067h51.2v51.2h-51.2ZM408.6,152.6H357.4V101.4h51.2Z"
          fill={color}
        />
        <path
          d="M229.4,203.8H92.867c-5.12,0-8.533,3.413-8.533,8.533V280.6c0,5.12,3.413,8.533,8.533,8.533H229.4c5.12,0,8.533-3.413,8.533-8.533V212.333C237.933,207.213,234.52,203.8,229.4,203.8Zm-128,17.067h51.2v51.2H101.4v-51.2Zm119.467,51.2h-51.2v-51.2h51.2Z"
          fill={color}
        />
        <path
          d="M417.133,203.8H280.6c-5.12,0-8.533,3.413-8.533,8.533V280.6c0,5.12,3.413,8.533,8.533,8.533H417.134c5.12,0,8.533-3.413,8.533-8.533V212.333C425.667,207.213,422.253,203.8,417.133,203.8Zm-128,17.067h51.2v51.2h-51.2Zm119.467,51.2H357.4v-51.2h51.2Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
