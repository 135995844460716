import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, {DesktopNavLinks, LogoLink, NavLink, NavToggle} from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25 h-full`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const LeftColumn = tw.div`pt-24 pb-32 px-4 items-center lg:block`;

const Heading = styled.h1`
  ${tw`text-2xl text-center sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

export default () => {
  return (
      <Container id="hero-container">
        <video
            id="vid"
            playsInline="playsinline"
            autoPlay="autoplay"
            muted="muted"
            loop="loop"
        >
          <source
              src="https://res.cloudinary.com/dg47fbat9/video/upload/q_auto:low/v1602601833/videoheader_yyb6fv.mp4"
              type="video/mp4"
          />
        </video>

        <OpacityOverlay/>
        <HeroContainer>
          <StyledHeader/>
          <LeftColumn>
            <Heading>
              <span>Prodávejte nemovitosti bez starostí</span>
              <br/>
              <SlantedBackground>Neveřejně</SlantedBackground>
            </Heading>
          </LeftColumn>
        </HeroContainer>
      </Container>
  );
};
