import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
  SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
  card = {
    id: 1,
    title: "Dvoupokojový byt v okrajové části Brna",
    tourLink: "https://my.matterport.com/show/?m=YKsMcWERZum",
    subtitle:
      "Nabízíme k prodeji příjemný dvoupokojový byt o výměře 60 m2 v městské části Brno – Bystrc.",
    monthly: "27.900 Kč",
    price: "5.000.000 Kč",
    images: [
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/1_dj7jo9.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/1_dj7jo9.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/2_vnwdst.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/2_vnwdst.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/3_wmyhmt.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/3_wmyhmt.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/4_b53kqv.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/4_b53kqv.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/5_owdp1c.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/5_owdp1c.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/6_tkczmd.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/6_tkczmd.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/7_jc9lwr.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/7_jc9lwr.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/8_hv458c.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/8_hv458c.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/9_zknb6a.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/9_zknb6a.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/10_eaxuiv.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/10_eaxuiv.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/11_nevi8a.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/11_nevi8a.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/12_aj6an9.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/12_aj6an9.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/13_ipiltp.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/13_ipiltp.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/14_lhedvc.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/14_lhedvc.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/15_prrotv.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/15_prrotv.jpg",
      },{
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1644217454/bystrc/16_a3c9i6.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1644217454/bystrc/16_a3c9i6.jpg",
      },
    ],
  },
}) => {
  return (
    <>
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 30,
          position: "absolute",
          zIndex: 10,
          width: "100%",
          height: "10rem",
          background:
            "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
          color: "white",
        }}
      >
        <Header links={navLinks} />
      </div>

      <ImageGallery
        showBullets
        showPlayButton={false}
        showThumbnails={false}
        items={card.images}
      />
      <AnimationRevealPage>
        <Container>
          <TwoColumn>
            <TitleColumn>
              <Heading>{card.title}</Heading>
              <SubHeading>{card.subtitle}</SubHeading>
              <Price>{card.price}</Price>
              <MonthlyDescription>
                splátka hypotéky <PricingMonthly>{card.monthly}</PricingMonthly>{" "}
                / měsíčně
              </MonthlyDescription>
            </TitleColumn>
            <PhoneColumn textOnLeft={false}>
              <IconWithText>
                <IconContainer>
                  <PhoneIcon />
                </IconContainer>
                <ContactDescription>
                  <Link href="tel:601 101 463">601 101 463</Link>
                </ContactDescription>
              </IconWithText>
            </PhoneColumn>
          </TwoColumn>
          <Description>
            Tento byt je jako{" "}
            <b>
              stvořený pro někoho, kdo hledá klidnou část města, ale zároveň
              rychlou dopravní dostupnost do centra Brna nebo na dálnici D1.
            </b>
          </Description>
          <Description>
            Byt na první pohled zaujme{" "}
            <b>praktickou dispozicí a otevřeností.</b> Pokoje jsou příjemně
            prosvětlené celodenním sluncem. Útulná chodba spojuje všechny části
            bytu a díky{" "}
            <b>samostatné komoře nabízí také dostatek úložného prostoru.</b> Z
            obývacího pokoje se dostanete na balkon, který je situován do{" "}
            <b>volné krajiny</b> a vybízí tak k odpolednímu relaxu u šálku dobré
            kávy.{" "}
            <b>
              {" "}
              K bytu náleží sklepní kóje, parkování je zabezpečeno před domem.{" "}
            </b>{" "}
            V roce 2011 proběhly v bytě stavební
            <b>
              {" "}
              úpravy (keramický obklad umakartového jádra, nové rozvody
              elektroinstalací, plastová okna s izolačním dvojsklem).
            </b>{" "}
            V roce 2015 pak proběhla <b>celková revitalizace domu.</b>
          </Description>
        </Container>
        <VirtualTour link={card.tourLink} />
        <Container>
          <Description>
            V okolí se nachází veškerá občanská vybavenost –{" "}
            <b>obchody, základní a mateřská škola, lékař, </b> a lokalita je
            velmi dobře{" "}
            <b>
              {" "}
              napojena na tramvajovou, trolejbusovou a autobusovou městskou
              dopravu.{" "}
            </b>{" "}
            Dům se nachází přibližně 140 m od zastávky autobusu Kavčí a cca 20
            min chůze od tramvajové zastávky Ečerova. A ani nudy se v této
            lokalitě obávat nemusíte – blízké okolí vybízí k{" "}
            <b>
              příjemným procházkám do přírody, např. kolem Brněnské přehrady,
              Podkomorskými lesy nebo k Žebětínskému rybníku.
            </b>
          </Description>
          <Description>
            Byt je vhodný jak vlastnímu bydlení, tak i pro investiční účely.
          </Description>
        </Container>
        <ContactForm subject={card.title} />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
