import React, {useState} from "react";
import Select from "react-select";
import tw from "twin.macro";
import {PrimaryButton} from "./misc/Buttons";

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col mx-auto lg:w-1/2`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const SubmitButton = tw(PrimaryButton)`inline-block mt-8`;

export default function LandForm({ handleSubmit }) {
  const [formData, setFormData] = useState({
    address: "",
    typ: "",
    plochaPozemku: "",
  });

  const optionsTyp = [
    { value: "stavebni", label: "Stavební" },
    { value: "Zahrada", label: "Zahrada" },
    { value: "jiny", label: "Jiný" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} id="emailForm">
        <Input
          type="address"
          name="Adresa"
          placeholder="Adresa"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({ ...prevData, address: value }));
          }}
        />
        <Input
          type="number"
          name="plocha pozemku"
          placeholder="Plocha pozemku (m2)"
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prevData) => ({
              ...prevData,
              plochaPozemku: value,
            }));
          }}
        />

        <div style={{ marginTop: "15px" }}>
          <Select
            options={optionsTyp}
            placeholder="Typ pozemku"
            onChange={({ value }) =>
              setFormData((prevData) => ({ ...prevData, typ: value }))
            }
          />
        </div>

        <SubmitButton type="submit">Odeslat</SubmitButton>
      </Form>
    </div>
  );
}
