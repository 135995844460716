import Footer from "components/footers/ContactFooter";
import {SectionHeading} from "components/misc/Headings.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import tw from "twin.macro";
import Header from "../components/headers/light";
import {Link} from "react-router-dom";
import ContactForm from "../components/forms/ContactForm";
import {ReactComponent as PhoneIcon} from "../images/phone-icon.svg";
import VirtualTour from "../components/misc/VirtualTour";
import {navLinks} from "components/misc/NavLinks";

const Container = tw.div`relative sm:mx-16 md:mx-40 pb-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between py-1`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TitleColumn = tw(Column)`md:w-8/12 lg:w-9/12 md:h-auto`;
const PhoneColumn = tw(Column)`md:w-4/12 lg:w-3/12 md:mt-0 sm:mr-4`;

const Heading = tw(
  SectionHeading
)`text-left text-xl sm:text-3xl lg:text-4xl text-center md:text-left text-gray-900`;

const SubHeading = tw.h5`font-bold lg:text-2xl md:text-xl text-center  md:text-left  mb-3 text-primary-900`;
const Price = tw.h2`font-bold lg:text-4xl md:text-3xl text-center  md:text-left  mb-1 text-primary-500`;
const PricingMonthly = tw.span`text-lg font-bold text-center text-primary-500`;
const MonthlyDescription = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-900`;

const Description = tw.p`text-justify md:text-justify text-base md:text-base lg:text-lg font-medium leading-relaxed text-gray-900 mt-8`;
const ContactDescription = tw.p`text-right md:text-right text-base md:text-base lg:text-2xl font-medium pl-2 leading-relaxed text-gray-900`;
const IconWithText = tw.div`flex rounded-full items-center py-8 `;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-primary-800 text-white border-gray-900`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

export default ({
  card = {
    title: "Exkluzivní rodinný dům na vyhledávané adrese",
    tourLink: "https://my.matterport.com/show/?m=92BCV2CCW1G",
    images: [
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/1_y0bywd.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/1_y0bywd.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/2_jfwjmn.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/2_jfwjmn.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/3-min_ygvwp9.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/3-min_ygvwp9.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/4_hkkcuc.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/4_hkkcuc.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/5-min_lgtikm.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/5-min_lgtikm.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/6-min_nv2klt.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/6-min_nv2klt.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/7_jh4r39.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/7_jh4r39.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/8_ajddmr.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/8_ajddmr.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/9_znsq8w.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/9_znsq8w.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/10-min_bj6hc1.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/10-min_bj6hc1.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/11-min_xyhdmg.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/11-min_xyhdmg.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/12_ckpgzn.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/12_ckpgzn.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/13_afesfe.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/13_afesfe.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/14_q8q6uy.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/14_q8q6uy.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/15_kqum8c.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/15_kqum8c.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/16-min_legkrr.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/16-min_legkrr.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/17-min_oglhr8.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/17-min_oglhr8.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/18_vbv79w.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/18_vbv79w.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/19_lkfxiq.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/19_lkfxiq.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/20_thzzjo.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/20_thzzjo.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/21_jedhm1.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/21_jedhm1.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/22_cgchqf.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/22_cgchqf.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/23_dt2wgb.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/23_dt2wgb.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/24_ekx4vm.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/24_ekx4vm.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/25_rvuv1w.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/25_rvuv1w.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/26_aykv19.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/26_aykv19.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/27_gy2m4z.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/27_gy2m4z.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/28_bb3t13.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/28_bb3t13.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/29_budx2x.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/29_budx2x.jpg",
      },
      {
        original:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,w_1920,q_auto:low/v1646734355/hnevotin/30_vizahi.jpg",
        thumbnail:
          "https://res.cloudinary.com/dg47fbat9/image/upload/f_auto,h_125,w_200,q_auto:low/v1646734355/hnevotin/30_vizahi.jpg",
      },
    ],
  },
}) => {
  return (
    <>
      <div
        style={{
          paddingTop: 15,
          paddingBottom: 10,
          paddingLeft: 30,
          position: "absolute",
          zIndex: 10,
          width: "100%",
          height: "10rem",
          background:
            "linear-gradient(180deg, rgba(26,32,44,1) 0%, rgba(26,32,44,0) 100%)",
          color: "white",
        }}
      >
        <Header links={navLinks} />
      </div>

      <ImageGallery
        showBullets
        showPlayButton={false}
        showThumbnails={false}
        items={card.images}
      />
      <AnimationRevealPage>
        <Container>
          <TwoColumn>
            <TitleColumn>
              <Heading>{card.title}</Heading>
              <SubHeading>
                byt s dispozicí 5+kk, 165 m<sup>2</sup>, Hněvotín, Olomouc
              </SubHeading>
              <Price>15 990 000 Kč</Price>
              <MonthlyDescription>
                splátka hypotéky <PricingMonthly>65.550 Kč</PricingMonthly> /
                měsíčně
              </MonthlyDescription>
            </TitleColumn>
            <PhoneColumn textOnLeft={false}>
              <IconWithText>
                <IconContainer>
                  <PhoneIcon />
                </IconContainer>
                <ContactDescription>
                  <Link href="tel:601 101 463">601 101 463</Link>
                </ContactDescription>
              </IconWithText>
            </PhoneColumn>
          </TwoColumn>
          <Description>
            Nabízíme k prodeji rodinný dům 5+kk o podlahové ploše 165 m2 ve{" "}
            <b>vyhledávané lokalitě obce Hněvotín,</b>
            nedaleko Olomouce.
          </Description>
          <Description>
            Tento dům je ideální pro{" "}
            <b>náročného klienta, který hledá vkusný a kvalitní dům,</b> do
            kterého není potřeba investovat – stačí položit kufry a bydlet.
            Nachází se zde totiž vše pro spokojený rodinný život s vysokými
            nároky.
          </Description>
          <Description>
            Majitel si tuto nemovitost stavěl sám, což se odrazilo na{" "}
            <b>použitých materiálech a kvalitním provedení.</b>
          </Description>
        </Container>
        <VirtualTour link={card.tourLink} />
        <Container>
          <Description>
            Jako první vcházíte do předsíně, která je vybavena praktickou
            šatníkovou sestavou a prostorným botníkem. Následuje chodba, která
            spojuje všechny místnosti ve spodní části, a to koupelnu, ložnici,
            sklep a srdce celého domu – obývací pokoj s kuchyní. Tu ocení
            zejména milovníci vaření, jelikož je řešena{" "}
            <b>
              prakticky a vybavena vestavěnými spotřebiči a americkou ledničkou.{" "}
            </b>{" "}
            V obývacím pokoji najdete <b>nadrozměrnou sedačku,</b>
            která vám poskytne útočiště pro nedělní lenošení či posezení s
            přáteli. Teplo domova zde navíc dotváří
            <b>krbová kamna, </b> která zpříjemní chladné večery. Výhodou je
            také <b>propojení na venkovní terasu a zahradu.</b>
          </Description>
          <Description>
            V 1. patře se nacházejí další{" "}
            <b>dvě koupelny, samostatná šatna, ložnice a dva pokoje.</b> Ty lze
            využít pro vaše děti, či jako pokoj pro hosty, pracovnu nebo
            posilovnu. Nad 1. patrem se rozléhá <b>půdní prostor,</b> který
            poslouží k uložení sezónních nezbytností.
          </Description>
          <Description>
            Celý dům se rozprostírá na{" "}
            <b>
              pozemku 542 m2, který disponuje automatickým zavlažováním a
              zahradním osvětlením.{" "}
            </b>{" "}
            Pozemek je oplocen, takže nehrozí žádné zvědavé pohledy sousedů.
            Soukromí si můžete užívat jak na <b> terase s bazénem,</b> tak v
            prostorné pergole, která je ideálním místem k posezení a večernímu
            relaxu při sklence vína nebo letní grilovačce. <b>Zahradní domek</b>{" "}
            poslouží k uložení všech nezbytných zahradních pomocníků. Benefitem
            je určitě i dospělá a udržovaná vegetace, která působí velmi
            příjemně. Nachází se zde také{" "}
            <b>prostorná garáž a další kryté parkovací místo pod střechou.</b>
          </Description>
          <Description>
            V celém domě se nachází{" "}
            <b>podlahové topení vytápěné tepelným čerpadlem voda – vzduch. </b>{" "}
            Ve sklepě je umístěna
            <b> čistička vody</b> zajišťující čistotu a optimální tvrdost.
          </Description>
          <Description>
            Celý dům chrání zabezpečovací{" "}
            <b>
              {" "}
              kamerový systém a celkově zde najdete několik prvků nadstandardní
              kvality (zabudovaný trezor, venkovní rolety, elektrickou bránu na
              dálkové ovládání, příprava na solární panely).
            </b>
          </Description>
          <Description>
            Lokalita nabízí nadprůměrnou{" "}
            <b>
              dopravní dostupnost, a to jak do Olomouce (10 min do centra), tak
              na dálnici (cca 3 min).{" "}
            </b>{" "}
            V obci se nachází veškerá občanská vybavenost - obchod, základní a
            mateřská škola, restaurace, lékaři, pošta a díky sportovištím také
            dostatek příležitostí k sportovnímu vyžití.
          </Description>
          <Description>
            <b>MOŽNOST NASTĚHOVÁNÍ 2Q/2023.</b>
          </Description>
        </Container>
        <ContactForm subject={card.title} />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
